import { Tag, TagFilter } from "@vp/models";

export class LoadTags {
  public static readonly type = "[Tags] Load Tags";
  constructor(public useCache: boolean = true) {}
}

export class CreateTag {
  public static readonly type = "[Tags] Create Tag";
  constructor(public tag: Tag) {}
}

export class SetSelectedTagId {
  public static readonly type = "[Tags] Set Selected TagId";
  constructor(public tagId: string) {}
}

export class UpdateTag {
  public static readonly type = "[Tags] Update Tag";
  constructor(public tag: Partial<Tag>) {}
}

export class DeleteTag {
  public static readonly type = "[Tags] Delete Tag";
  constructor(public tagId: string) {}
}

export class SetDefaultFilter {
  public static readonly type = "[Tags] Set Default Filter";
  constructor(public tagFilter: TagFilter) {}
}

export class SetSelectedTags {
  public static readonly type = "[Tags] Set Selected Tags";
  constructor(public selectedTags: Tag[] = []) {}
}

export class SetTagState {
  public static readonly type = "[Tags] Set TagState";
  constructor(public tagState: Tag | null) {}
}

export class LoadTagState {
  public static readonly type = "[Tags] Load Tag State";
  constructor(public tagData: Partial<Tag>) {}
}

export class SetFiltered {
  public static readonly type = "[Tags] Get Assignable Tags";
  public constructor(public tagFilter: Partial<TagFilter>, public useCache: boolean) {}
}
