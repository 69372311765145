import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { ContentFilterState } from "@vp/data-access/content-management";
import { TagsState } from "@vp/data-access/tags";
import { ContentData, OrganizationFeatures, Tag, User } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { deepCopy, insert } from "@vp/shared/utilities";
import { UserAdministrationService } from "@vp/user-administration/data-access/user-administration-state";
import { BehaviorSubject, EMPTY, Observable, Subject, zip } from "rxjs";
import { filter, map, switchMap, take, takeUntil, withLatestFrom } from "rxjs/operators";

interface ToSAcknowledgment {
  language: string;
  acknowledgmentMessage: string;
  buttonLabel: string;
}

@Component({
  selector: "vp-legal-notice",
  templateUrl: "./legal-notice.component.html",
  styleUrls: ["./legal-notice.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserAdministrationService],
  queries: {
    templateRef: new ViewChild("template")
  }
})
export class LegalNoticeComponent implements OnDestroy {
  @Select(ContentFilterState.allContents) contents$!: Observable<ContentData[]>;
  @Select(TagsState.tags) allTags$!: Observable<Tag[]>;

  destroyed = new Subject();
  form$!: Observable<UntypedFormGroup>;
  legalNoticeRequested$: Observable<boolean>;
  templateRef!: ElementRef;

  private _form$ = new BehaviorSubject<UntypedFormGroup | null>(null);
  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly appStoreService: AppStoreService,
    private readonly featureService: FeatureService
  ) {
    this._form$.next(
      this.formBuilder.group({
        legalNoticedAccepted: [false, Validators.requiredTrue]
      })
    );

    this.form$ = this._form$.pipe(filterNullMap());
    this.legalNoticeRequested$ = this.appStoreService.user$.pipe(
      map((user: User) => {
        return user?.userData?.flags?.legalNoticeRequested || true;
      }),
      takeUntil(this.destroyed)
    );
  }

  profileLangaugeTag$ = this.allTags$.pipe(
    filter(tags => tags.length > 0),
    take(1),
    withLatestFrom(this.appStoreService.user$),
    map(([tags, user]) => {
      return tags.find(t => t.tagId === user.profile.language);
    })
  );

  defaultToSLanguageTag$ = this.allTags$.pipe(
    filter(tags => tags.length > 0),
    take(1),
    withLatestFrom(
      this.featureService.configurationValue$(
        OrganizationFeatures.termsOfService,
        "defaultToSLanguage"
      )
    ),
    map(([allTags, defaultLanguage]) => {
      return allTags.find(t => t.displayName === defaultLanguage);
    }),
    filterNullMap()
  );

  toSAcknowledgement$ = zip(
    this.profileLangaugeTag$,
    this.defaultToSLanguageTag$,
    this.featureService.configurationDictionaryValue$<ToSAcknowledgment>(
      OrganizationFeatures.termsOfService,
      "toSAcknowledgment"
    )
  );

  acknowledgmentMessage$ = this.toSAcknowledgement$.pipe(
    map(
      ([profileLanguageTag, defaultLanguageTag, toSAcknowledgment]: [
        Tag | undefined,
        Tag,
        ToSAcknowledgment[]
      ]) => {
        const acknowledgmentMessage = toSAcknowledgment.find(
          t => t.language === profileLanguageTag?.displayName
        )?.acknowledgmentMessage;
        const defaultMessage = toSAcknowledgment.find(
          t => t.language === defaultLanguageTag.displayName
        )?.acknowledgmentMessage;
        return (
          acknowledgmentMessage ||
          defaultMessage ||
          "Check here to acknowledge you have read and understand the terms of service.<br />Marque aquí para reconocer que ha leído y entendido los términos de servicio."
        );
      }
    )
  );

  buttonLabel$ = this.toSAcknowledgement$.pipe(
    map(
      ([profileLanguageTag, defaultLanguageTag, toSAcknowledgment]: [
        Tag | undefined,
        Tag,
        ToSAcknowledgment[]
      ]) => {
        const buttonLabel = toSAcknowledgment.find(
          t => t.language === profileLanguageTag?.displayName
        )?.buttonLabel;
        const defaultButtonLabel = toSAcknowledgment.find(
          t => t.language === defaultLanguageTag.displayName
        )?.buttonLabel;
        return buttonLabel || defaultButtonLabel || "Accept/Aceptar";
      }
    )
  );

  toS$ = this.contents$.pipe(
    withLatestFrom(this.appStoreService.user$, this.defaultToSLanguageTag$),
    map(([contents, user, defaultLanguageTag]: [ContentData[], User, Tag]) => {
      const defaultToS = contents.find(
        c => c.contentTypeId === "terms-of-service" && c.tags.includes(defaultLanguageTag.tagId)
      );
      const languageTagId = user.profile.language ?? defaultLanguageTag.tagId;

      const toS = contents.find(
        (contentData: ContentData) =>
          contentData.contentTypeId === "terms-of-service" &&
          contentData.tags.includes(languageTagId)
      );

      return toS?.content ?? defaultToS?.content;
    })
  );

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  onAccept() {
    this.form$
      .pipe(
        take(1),
        withLatestFrom(this.appStoreService.user$),
        switchMap(([form, user]: [UntypedFormGroup, User]) => {
          if (form.value.legalNoticedAccepted === true) {
            const modified = deepCopy(user);
            // set legalNoticeRequested to false on the user so they are no longer prompted
            insert({
              targetObject: modified,
              props: ["userData", "flags"],
              objToInsert: {
                legalNoticeRequested: false
              }
            });
            return this.appStoreService.patchUser(modified, "user_acceptLegalNotice");
          }
          form.markAllAsTouched();
          return EMPTY;
        })
      )
      .subscribe({
        next: () => {
          this.router.navigate(["/default"]);
        }
      });
  }
}
