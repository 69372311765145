import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { RequestMetaDataResolver } from "./request-meta-data-resolver";
import { RequestMetaDataState } from "./state+/request-meta-data.state";

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([RequestMetaDataState])],
  providers: [RequestMetaDataResolver]
})
export class RequestMetaDataStateModule {}
