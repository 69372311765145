import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TrackByFunction,
  ViewEncapsulation
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";

import { Tag } from "@vp/models";
import { Differences, TagGroup, getTagDifferences } from "@vp/shared/utilities";

import { BehaviorSubject, Subject } from "rxjs";

export interface TagChangedEvent {
  tagTypeFriendlyId: string;
  tags: string[];
}

@Component({
  selector: "vp-multi-tag-selector",
  templateUrl: "./multi-tag-selector.component.html",
  styleUrls: ["./multi-tag-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MultiTagSelectorComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() selectedTags: Tag[] = [];
  @Input() tagTypes: string[] = [];
  @Input() tagGroups: TagGroup[] = [];
  @Output() tagChanged = new EventEmitter<TagChangedEvent>();

  private destroyed$ = new Subject();

  private tagGroups$$ = new BehaviorSubject<TagGroup[]>([]);
  private selectedTags$$ = new BehaviorSubject<Tag[]>([]);

  public formGroup = new UntypedFormGroup({});
  public formControls: { [key: string]: UntypedFormControl } = {};
  private formChanges$ = new BehaviorSubject<SimpleChanges>({});

  selectedTags$ = this.selectedTags$$.asObservable();

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.tagTypes.forEach(tagType => {
      this.formControls[tagType] = new UntypedFormControl([]);
    });
    this.formGroup = new UntypedFormGroup(this.formControls);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formChanges$.next(changes);
  }

  ngAfterViewInit(): void {
    this.tagGroups$$.next(
      this.tagGroups.filter(tagGroup => this.tagTypes.includes(tagGroup.tagTypeFriendlyId))
    );

    // This prevents the ngOnChanges from emitting until after the view has been initialized
    this.formChanges$.subscribe((changes: SimpleChanges) => {
      const _previous = [...(changes.selectedTags?.previousValue ?? [])];
      const _current = [...(changes.selectedTags?.currentValue ?? [])];
      this.updateSelectedTags(_previous, _current);
      this.selectedTags$$.next(_current);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getParentGroup(tagTypeFriendlyId: string): TagGroup[] {
    return this.tagGroups.filter(tg => tg.tagTypeFriendlyId === tagTypeFriendlyId);
  }

  getSelectedGrouping(tagTypeFriendlyId: string): TagGroup[] {
    const _selectedTags = this.selectedTags$$.getValue();
    const parentTagTypes =
      this.store
        .selectSnapshot(OrganizationState.tagTypes)
        .find(tt => tt.friendlyId == tagTypeFriendlyId)
        ?.tagTypeFriendlyPathId?.split(".") ?? [];
    const parentTagTypeFriendlyId = parentTagTypes[parentTagTypes.length - 1] ?? null;
    const selectedGrouping = this.tagGroups.filter(
      tg =>
        _selectedTags.map(t => t.tagId).includes(tg.key) &&
        tg.tagTypeFriendlyId === parentTagTypeFriendlyId
    );
    return selectedGrouping;
  }

  getChildrenOfType(tagGroups: TagGroup[], tagTypeFriendlyId: string) {
    return tagGroups.filter(tg => tg.tagTypeFriendlyId === tagTypeFriendlyId);
  }

  onTagChanged(eventValue: string[], tagTypeFriendlyId: string): void {
    this.tagChanged.emit({ tagTypeFriendlyId, tags: eventValue });
  }

  trackGroupByTagId: TrackByFunction<TagGroup> = (_: number, tagGroup: TagGroup) =>
    tagGroup.tag.tagId;
  trackByTagId: TrackByFunction<Tag> = (_: number, tag: Tag) => tag.tagId;

  private updateSelectedTags(previous: Tag[], current: Tag[]): void {
    if (Object.keys(this.formControls).length === 0) return;
    const groupedTagIds = getGroupedTags(this.tagTypes, previous, current);
    Object.entries(groupedTagIds).forEach(([key, values]: [string, string[]]) => {
      this.formControls[key]?.setValue(values);
    });
  }

  private filterDataById(data: TagGroup[], id: string): TagGroup[] {
    let result: TagGroup[] = [];
    data.forEach(item => {
      if (item.tagTypeFriendlyId === id) {
        result.push(item);
      }
      if (item.children) {
        result = result.concat(this.filterDataById(item.children, id));
      }
    });
    return result;
  }
}

const getGroupedTags = (tagTypes: string[], previous: Tag[], current: Tag[]) =>
  tagTypes.reduce((acc: Record<string, string[]>, tagType: string) => {
    const diffs: Differences = getTagDifferences(previous, current, tagType);
    acc[tagType] = current
      .filter(t => !diffs.removed.includes(t.tagId) && t.tagTypeFriendlyId === tagType)
      .map(t => t.tagId);
    return acc;
  }, {});
