import { ContentData } from "@vp/models";
import { ContentDataFilter } from "../models/content-data-filter";
import { ContentOperations } from "../models/content-operations.model";

export class SetFilter {
  public static readonly type = "[ContentSearch Filter] Set Filter";
  constructor(public filter: Partial<ContentDataFilter>) {}
}

export class GetFiltered {
  public static readonly type = "[ContentSearch Filter] Get Filtered";
}

export class LoadContents {
  public static readonly type = "[Contents] Load All Contents";
}

export class AddContent {
  public static readonly type = "[Content Data] Add Content";
  constructor(public content: ContentData) {}
}

export class DeleteContent {
  public static readonly type = "[Content Data] Delete Content";
  constructor(public contentId: string) {}
}
export class LoadContentData {
  public static readonly type = "[Content Data] Load Content Data";
  constructor(public contentData: Partial<ContentData>, public active: boolean = true) {}
}

export class SetWorkingCopy {
  public static readonly type = "[Content Data] Set Working Copy";
  constructor(public contentData: ContentData) {}
}

export class CommitOperations {
  public static readonly type = "[Content Data] Commit Operations";
}

export class SetContentData {
  public static readonly type = "[Content Data] Set Content Data";
  constructor(public contentData: ContentData) {}
}

export class SetPendingOperations {
  public static readonly type = "[Content Data] Set Pending Operations";
  constructor(public contentOperations: ContentOperations) {}
}
