import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Department, Organization, Template } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { deeperCopy, sortItems } from "@vp/shared/utilities";
import { tap } from "rxjs/operators";
import { OrganizationApiService } from "../api/organization-api.service";
import * as OrganizationActions from "./organization-actions";

export interface OrganizationStateModel {
  organization: Organization | null;
  templates: Template[];
}

@State<OrganizationStateModel>({
  name: "organization",
  defaults: { organization: null, templates: [] }
})
@Injectable()
export class OrganizationState {
  constructor(private readonly _organizationApiService: OrganizationApiService) {}

  @Selector()
  public static getOrganization(state: OrganizationStateModel): Organization | null {
    return state.organization;
  }

  // TODO: This needs to not be nullable
  @Selector([OrganizationState.getOrganization])
  public static organization(organization: Organization | null): Organization | null {
    return deeperCopy(organization);
  }

  @Selector()
  public static templates(state: OrganizationStateModel) {
    return [...state.templates];
  }

  @Selector()
  public static departments(state: OrganizationStateModel) {
    const departments: Department[] = state.organization?.departments ?? [];
    return sortItems(departments, ["displayName"], "asc");
  }

  @Selector([OrganizationState.organization])
  public static tagTypes(organization: Organization) {
    if (!organization?.tagTypes) return [];
    return [...organization.tagTypes];
  }

  @Selector()
  public static contentTypes(state: OrganizationStateModel) {
    return [...(state.organization?.contentTypes ?? [])];
  }

  @Selector()
  public static groupTypes(state: OrganizationStateModel) {
    return [...(state.organization?.groupTypes ?? [])];
  }

  @Selector()
  public static userTypes(state: OrganizationStateModel) {
    return [...(state.organization?.userTypes ?? [])];
  }

  // ngxsOnInit(ctx: StateContext<OrganizationStateModel>) {
  //   ctx.dispatch(OrganizationActions.LoadOrganization);
  // }

  @Action(OrganizationActions.LoadOrganization)
  load(ctx: StateContext<OrganizationStateModel>) {
    return this._organizationApiService.getOrganization().pipe(
      tap((organization: Organization) => {
        ctx.patchState({ organization: organization });
      })
    );
  }

  @Action(OrganizationActions.PatchOrganization)
  patch(
    ctx: StateContext<OrganizationStateModel>,
    { opertaions: operations }: OrganizationActions.PatchOrganization
  ) {
    return this._organizationApiService.patch(operations).pipe(
      tap((organization: Organization) => {
        ctx.patchState({ organization: organization });
      })
    );
  }

  @Action(OrganizationActions.LoadTemplates)
  loadTemplates(ctx: StateContext<OrganizationStateModel>) {
    return this._organizationApiService.getTemplates().pipe(
      filterNullMap(),
      tap((templates: Template[]) => {
        ctx.patchState({ templates: templates });
      })
    );
  }
}
