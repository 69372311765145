import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Store } from "@ngxs/store";
import { Tag, TagFilter } from "@vp/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as TagsActions from "./state+/tags-actions";
import { TagsState } from "./state+/tags-state";

@Injectable()
export class FilteredTagsResolver implements Resolve<Tag[]> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot): Tag[] | Observable<Tag[]> | Promise<Tag[]> {
    const tagFilter: Partial<TagFilter> = route.data?.tagFilter;
    const caseId = route.params["caseId"];
    if (caseId) {
      tagFilter.caseId = caseId;
    }

    return this.store
      .dispatch(new TagsActions.SetFiltered(tagFilter, false))
      .pipe(map(() => this.store.selectSnapshot(TagsState.filtered)));
  }
}
