<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    showRolesAndGroups: (showRolesAndGroups$ | async) || false,
    reason: (reason$ | async) || null,
    user: (user$ | async),
    optIn: (optIn$ | async)
  }"
>
</ng-container>

<ng-template
  #template
  let-showRolesAndGroups="showRolesAndGroups"
  let-reason="reason"
  let-user="user"
  let-optIn="optIn"
>
  <div flexLayout="row" flexLayoutAlign="center stretch">
    <div
      flexLayout="column"
      flexLayoutAlign="start center"
      [ngClass]="showRolesAndGroups ? 'showEnabled' : 'showDefault'"
    >
      <mat-card *ngIf="reason" flexLayout="row" flexLayoutAlign="start center" flexLayoutGap="16px">
        <mat-icon [color]="reason.type">info</mat-icon>
        <div flexLayout="column" flexLayoutAlign="start stretch">
          <span>{{ reason.title }}</span>
          <span>{{ reason.reason }}</span>
        </div>
      </mat-card>

      <mat-card>
        <div flexFill flexLayout="column" flexLayoutAlign="space-between stretch">
          <div flexLayout="row wrap" flexLayoutGap="16px grid" flexLayoutAlign="start stretch">
            <formly-form
              [form]="form"
              [fields]="fields"
              [model]="model"
              [options]="options"
            ></formly-form>

            <ng-template featureFlag="tosEnabled" feature="profile">
              <a (click)="tosInformation()"
                ><u>{{ uiDisplayTagService.displayTags.tosInformationLinkLabel }}</u></a
              >
            </ng-template>

            <ng-template featureFlag="bioEnabled" feature="profile">
              <vp-user-bio></vp-user-bio>
            </ng-template>

            <div
              flexLayout="column"
              flex="100"
              flexLayoutAlign="start stretch"
              flexLayoutGap="16px"
            >
              <div
                flexLayout="column"
                flexLayoutGap="16px"
                flexLayoutAlign="start stretch"
                *ngxPermissionsOnly="[this.permConst.Profile.DeptmentAndGroup.Read]"
              >
                <div
                  class="wrapper"
                  *ngxPermissionsOnly="[permConst.Admin.User.RoleAssignment.Read]"
                >
                  <vp-user-assigned-roles></vp-user-assigned-roles>
                </div>
                <div
                  class="wrapper"
                  *ngxPermissionsOnly="[permConst.Admin.User.TagsAssignment.Read]"
                >
                  <vp-user-assigned-tags></vp-user-assigned-tags>
                </div>
              </div>
              <div class="wrapper" *ngxPermissionsOnly="[permConst.Admin.User.Snippet.Read]">
                <vp-user-snippets></vp-user-snippets>
              </div>
            </div>

            <ng-template [featureEnabled]="'smsOptIn'">
              <mat-checkbox [checked]="optIn" (change)="toggleOptIn($event.checked)">
                {{ optInVerbiage | async }}
              </mat-checkbox>
            </ng-template>
          </div>
          <div
            class="action-row"
            flexLayout="row"
            flexLayoutAlign="space-between center"
            flexLayoutGap="16px"
          >
            <div flexLayout="row" flexLayoutAlign="start center" *ngIf="hasPending$ | async">
              <span *ngIf="form.invalid === false">This form has pending changes.</span>
              <span *ngIf="form.invalid === true">Please fix invalid fields.</span>
            </div>
            <div flexLayout="row" flexLayoutAlign="end center">
              <button mat-flat-button type="button" (click)="returnToPreviousRoute()">
                {{ form.dirty ? "Cancel" : "Close" }}
              </button>
              <button
                mat-flat-button
                color="primary"
                type="submit"
                [disabled]="form.invalid"
                (click)="onSave()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</ng-template>
