<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    quickSearchTag: (quickSearchTag$ | async),
    isLoggedIn: (authenticationService.isLoggedIn$() | async)
  }"
>
</ng-container>

<ng-template #template let-quickSearchTag="quickSearchTag" let-isLoggedIn="isLoggedIn">
  <div
    flex
    class="search-container"
    flexLayout="row"
    *ngIf="isLoggedIn?.isAuthenticated"
    flexLayoutAlign="space-between center"
  >
    <input
      title="Search Currently Displayed Results &#10;  &#x23ce;  Press Enter to Search &#10; &#x232b; Press Delete to Clear"
      id="searchInput"
      #searchinput
      matInput
      class="search-input"
      [placeholder]="
        quickSearchTag
          ? uiDisplayTagService.displayTags[quickSearchTag]
          : quickSearchTag || 'Search'
      "
      (keyup)="checkIfEmpty($event)"
      (keyup.enter)="SearchNow()"
      [(ngModel)]="input"
      focused="focused"
    />
    <div matSuffix class="suffix">
      <button type="button" *ngIf="isSearching()" mat-icon-button (click)="clear()">
        <mat-icon color="accent" aria-hidden="false" class="icon" inline="true">close</mat-icon>
      </button>
      <button type="button" *ngIf="!isSearching()" mat-icon-button (click)="SearchNow()">
        <mat-icon color="accent" aria-hidden="false" class="icon" inline="true">search</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
