import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Actions, Select, Store, ofActionSuccessful } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import * as TagsActions from "@vp/data-access/tags";
import { TagsState } from "@vp/data-access/tags";
import { Tag, TagType } from "@vp/models";
import { LoggerService } from "@vp/shared/logger-service";
import { filterNullMap } from "@vp/shared/operators";
import { createPatch } from "rfc6902";
import { EMPTY, Observable, Subject, combineLatest, of, zip } from "rxjs";
import {
  delay,
  exhaustMap,
  filter,
  first,
  map,
  pairwise,
  skip,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from "rxjs/operators";
import * as TagManagerActions from "../state+/tag-manager.actions";
import { TagManagerState } from "../state+/tag-manager.state";
export type OperationMode = "single" | "bulk";

@Injectable()
export class TagManagerActionsService implements OnDestroy {
  @Select(TagManagerState.selectedTagTypes) selectedTagTypes$!: Observable<TagType[]>;
  @Select(TagManagerState.selectedTag) selectedTag$!: Observable<Tag | null>;
  @Select(TagManagerState.selectedTagType) selectedTagType$!: Observable<TagType>;
  @Select(OrganizationState.tagTypes) tagTypes$!: Observable<TagType[]>;
  @Select(TagsState.filtered) tags$!: Observable<Tag[]>;
  @Select(TagManagerState.getUiState) uiState$!: Observable<(filter: string) => boolean | string>;

  private destroy$ = new Subject();

  constructor(
    private readonly loggerService: LoggerService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly actions: Actions
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  listen() {
    /**
     * update the tag in tag state every time anything is committed form the tag manager to make sure the global
     * state gets updated.
     */
    combineLatest([this.actions.pipe(ofActionSuccessful(TagManagerActions.CommitOperations))])
      .pipe(
        switchMap(() => this.selectedTag$.pipe(filterNullMap(), take(1))),
        switchMap((selectedTag: Tag) =>
          this.store.dispatch(new TagsActions.UpdateTag(selectedTag))
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();

    /**
     * This code handles merging of changes into the operations array. With each change a
     * diff is calculated. Then the operations are merged in the state. For example
     * if you have an existing replace operation on /subject/txtFirst name, then any
     * subsequent changes the same path will update that operation so the operations
     * are always up to date with the their respective path in the state.
     */
    this.selectedTag$
      .pipe(
        pairwise(),
        map(([previousTag, currentTag]) => {
          if (!previousTag?.tagId && !currentTag?.tagId) {
            return [];
          }
          if (previousTag?.tagId === currentTag?.tagId) {
            return createPatch(previousTag, currentTag);
          }
          return [];
        }),
        withLatestFrom(this.uiState$.pipe(map(filterFn => filterFn("editStatus"))))
      )
      .subscribe(([operations, editStatus]) => {
        if (editStatus !== "cancelled" && operations.length > 0) {
          this.store.dispatch(new TagManagerActions.MergeOperations(operations));
        }
      });

    // TODO: Support search box from the top nav menu on this page?
    this.activatedRoute.queryParamMap
      .pipe(
        exhaustMap(() => {
          //const search: string | null = paramMap.get("search");
          //const activated = this.store.selectSnapshot(TagManagerState.activated);
          //console.log(`search: ${search} activated: ${JSON.stringify(activated)}`);
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    /**
     * This subscription sets up the initial state, supplying tags and tag types from the
     * tags and tagtype state services. This also handles pulling the tag id and tag type id
     * from the url so the page will refresh/deep link to specific states.
     */
    zip(
      this.tagTypes$,
      this.tags$.pipe(filter((tags: Tag[]) => tags.length > 0)),
      of(getStateFromParams(this.activatedRoute.snapshot.queryParamMap))
    )
      .pipe(first())
      .subscribe({
        next: ([tagTypes, tags, queryParams]: [TagType[], Tag[], QueryParams]) => {
          if (queryParams.tagId && queryParams.tagTypeId) {
            const activatedTag = tags.find(t => t.tagId === queryParams.tagId) ?? null;
            const activatedTagType =
              tagTypes.find(tt => tt.tagTypeId === queryParams.tagTypeId) ?? null;
            const activatedTagTypes = getActivatedTagTypes(
              tagTypes.find(tt => tt.tagTypeId === queryParams.tagTypeId),
              tagTypes
            );
            const activatedTags = getActivatedTags(activatedTag, tags);
            const orderedTags = orderByPath(activatedTags);
            this.store.dispatch(
              new TagManagerActions.UpdateState({
                tags: [...tags],
                tagTypes: [...tagTypes],
                selectedTagType: activatedTagType,
                selectedTag: activatedTag,
                selectedTagTypes: activatedTagTypes,
                selectedTags: orderedTags
              })
            );
          } else {
            this.store.dispatch(
              new TagManagerActions.UpdateState({
                tags: [...tags],
                tagTypes: [...tagTypes]
              })
            );
          }
        },
        error: (error: any) => {
          this.loggerService.errorEvent(
            error,
            `${this.constructor.name}.${this.listen.name}`,
            "An Error occured initializing the queryParamMap/tags subscription."
          );
        }
      });

    /**
     * This subscription simply keeps the url in sync with the activated property in the state
     * without causing a navigation event.
     */
    combineLatest([this.selectedTagType$, this.selectedTag$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([tagType, tag]: [TagType, Tag | null]) => {
        const queryParams: Partial<QueryParams> = {};
        if (tagType) {
          queryParams["tagTypeId"] = tagType.tagTypeId;
          if (tag) {
            queryParams["tagId"] = tag.tagId;
          }
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            skipLocationChange: false,
            replaceUrl: true
          });
        }
      });

    this.actions
      .pipe(
        ofActionSuccessful(TagManagerActions.CancelEditingTag),
        delay(1000),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.store.dispatch(
          new TagManagerActions.PatchUiState({
            editStatus: "untouched"
          })
        );
      });

    /**
     * This subscription will update the tag manager state when the filtered tag state updates
     */
    this.tags$
      .pipe(
        filter((tags: Tag[]) => tags.length > 0),
        skip(1), //Skip the first emit which is handled by the setup
        takeUntil(this.destroy$)
      )
      .subscribe(tags => {
        this.store.dispatch(
          new TagManagerActions.UpdateState({
            tags: [...tags]
          })
        );
      });
  }
}

const getStateFromParams = (paramMap: ParamMap) => {
  const tagTypeId = paramMap.get("tagTypeId");
  const tagId = paramMap.get("tagId");

  return {
    tagTypeId: tagTypeId,
    tagId: tagId
  } as QueryParams;
};

interface QueryParams {
  tagTypeId: string | null | undefined;
  tagId: string | null | undefined;
}

const orderByPath = (tags: Tag[]) => {
  return [...tags].sort((a: Tag, b: Tag) => {
    if (a.tagPath && !b.tagPath) {
      return 1;
    } else if (b.tagPath && !a.tagPath) {
      return -1;
    } else if (a.tagPath?.split(".").includes(b.tagId)) {
      return 1;
    } else if (b.tagPath?.split(".").includes(a.tagId)) {
      return -1;
    } else return 0;
  });
};

/**
 * For a given tag type, calculate all "parent" tag types in the tag types array.
 * this is used to pre-select parent tag types when a single tag type is provided
 * in the url.
 * @param tagType
 * @param tagTypes
 * @returns
 */
const getActivatedTagTypes = (tagType: TagType | undefined, tagTypes: TagType[]) => {
  if (!tagType) return [];
  return tagTypes.reduce((acc: TagType[], item: TagType) => {
    if (
      item.tagTypeId === tagType.tagTypeId ||
      tagType.tagTypeFriendlyPathId?.split(".").includes(item.friendlyId)
    ) {
      acc.push(item);
    }
    return acc;
  }, []);
};

/**
 * For a given tag , calculate all "parent" tag in the tag array. this is used to
 * pre-select parent tag types when a single tag type is provided in the url.
 * @param tag
 * @param tags
 * @returns
 */
const getActivatedTags = (tag: Tag | null, tags: Tag[]) => {
  if (!tag) return [];
  return tags.reduce((acc: Tag[], item: Tag) => {
    if (item.tagId == tag.tagId || tag.tagPath?.split(".").includes(item.tagId)) {
      acc.push(item);
    }
    return acc;
  }, []);
};
