import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as RequestMetaDataActions from "./state+/request-meta-data-state.actions";
import { RequestMetaDataState } from "./state+/request-meta-data.state";

@Injectable()
export class RequestMetaDataResolver implements Resolve<Record<string, string>> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Record<string, string>> {
    const metaData: Record<string, string> = route.data?.metaData;

    return this.store
      .dispatch(new RequestMetaDataActions.SetMetaData(metaData))
      .pipe(map(() => this.store.selectSnapshot(RequestMetaDataState)));
  }
}
