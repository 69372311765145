import { IEvent } from "@vp/models";

import { Alert, ApplicationStateModel } from "./application.state";

export class PatchState {
  public static readonly type = "[applicationState] Patch State";
  constructor(public partialState: Partial<ApplicationStateModel>) {}
}

export class ResetState {
  public static readonly type = "[applicationState] Reset State";
}

export class EmitEvent {
  static readonly type = "[applicationState] Emit Event";
  constructor(public event: IEvent, public sender: string) {}
}

export class EventHandled {
  static readonly type = "[applicationState] Event Handled";
  constructor(public event: IEvent) {}
}

export class AddAlert {
  static readonly type = "[applicationState] Add Alert";
  constructor(public alert: Alert | Alert[]) {}
}

export class AcknowledgeAlert {
  public static readonly type = "[applicationState] Update User Data";
  constructor(public userId: string, public alertId: string) {}
}
