import { Tag, TagType } from "@vp/models";
import { TagManagerStateModel } from "../models/tag-manager-state.model";

/**
/**
 * Puts the model in a proper state when a tag is unselected. This calculates at what level
 * a tag type is removed and removes correlated tag types. Unlike removing the tags, this
 * actually just resets the selected tags because removing the tag types forces you to reselect
 * tags logically.
 * @param selectedTags 
 * @param selectedTagTypes 
 * @param tagTypeToUnselect 
 * @returns 
 */
export const unselectTagType = (
  selectedTags: Readonly<Tag[]>,
  selectedTagTypes: Readonly<TagType[]>,
  tagTypeToUnselect: Readonly<TagType>
) => {
  const replacementTagTypes = selectedTagTypes.filter(
    tagType =>
      tagTypeToUnselect.tagTypeFriendlyPathId?.split(".").includes(tagType.friendlyId) ?? false
  );

  const replacementTags = selectedTags.filter(tag =>
    replacementTagTypes.map(tt => tt.friendlyId).includes(tag.tagTypeFriendlyId)
  );

  return {
    selectedTags: replacementTags,
    selectedTagTypes: replacementTagTypes,
    selectedTagType: null,
    selectedTag: null
  } as Partial<TagManagerStateModel>;
};

/**
 * Puts the model in a proper state when a tag is unselected. This calculates at what level
 * a tag is removed and removes correlated tag types as well as all tags dependent on the tag
 * being removed. So if you have 3 tag types and 3 tags selected, and you remove the second
 * tag, the third tag and tag type will also be removed.
 * @param state
 * @param selectedTags
 * @param tagToUnselect
 * @returns {Partial<TagManagerStateModel> | null}
 */
export const unselectTag = (
  state: Readonly<TagManagerStateModel>,
  selectedTags: Readonly<Tag[]>,
  tagToUnselect: Readonly<Tag>
) => {
  const selectedTagTypes = [...state.selectedTagTypes];
  const focusedTagTypeId = state.selectedTagType?.tagTypeId;
  const focusedTagType = selectedTagTypes.find(
    (tagType: TagType) => tagType.tagTypeId === focusedTagTypeId
  );

  if (tagToUnselect?.tagPath) {
    const replacementTags = selectedTags.filter(t =>
      tagToUnselect.tagPath.split(".").includes(t.tagId)
    );

    const replacementTagTypes =
      selectedTags.map(t => t.tagId).indexOf(tagToUnselect.tagId) === selectedTags.length - 1 &&
      selectedTagTypes.length === selectedTags.length
        ? selectedTagTypes
        : selectedTagTypes.filter(tt =>
            [...new Set(replacementTags.map(t => t.tagTypeId))].includes(tt.tagTypeId)
          );

    const replacementFocusedTagType: TagType =
      focusedTagType && selectedTagTypes.indexOf(focusedTagType) === selectedTagTypes.length - 1
        ? focusedTagType
        : replacementTagTypes[replacementTagTypes.length - 1] ?? null;

    return {
      selectedTags: replacementTags,
      selectedTagTypes: replacementTagTypes,
      selectedTagType: replacementFocusedTagType
    } as Partial<TagManagerStateModel>;
  }
  return null;
};
