import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { CaseState } from "@vp/data-access/case";
import { TagsApiService } from "@vp/data-access/tags";
import { FormStateProvider } from "@vp/formly/ui-schema-config";
import { Observable, from } from "rxjs";
import { concatMap, map, reduce } from "rxjs/operators";

@Injectable()
export class TagsFormStateProviderService implements FormStateProvider {
  constructor(private tagsApiService: TagsApiService, private store: Store) {}

  provide(
    state: Record<string, unknown>,
    context?: Record<string, unknown>
  ): Observable<Record<string, unknown>> {
    if (Object.keys(state).length === 0) return from([{}]);

    const stateTags = state.tags as Record<string, unknown>;
    const caseId =
      this.store.selectSnapshot(CaseState.current)?.caseId || (context?.caseId as string);
    if (stateTags && Object.keys(stateTags).length > 0) {
      return from(Object.entries(stateTags)).pipe(
        concatMap(([key, value]: [string, any]) =>
          this.tagsApiService
            .getTags(
              {
                assignable: value?.assignable === true,
                accessible: value?.accessible === true,
                caseId
              },
              true,
              2000
            )
            .pipe(
              map(tags => ({
                [key]: [
                  ...tags
                    .filter(
                      tag =>
                        tag.tagTypeFriendlyId === value?.tagTypeFriendlyId && tag.active === true
                    )
                    .sort((a, b) => a.displayName.localeCompare(b.displayName))
                ]
              }))
            )
        ),
        reduce((accumulator, current) => ({ ...accumulator, ...current }), { ...stateTags })
      );
    }
    return from([{}]);
  }
}
