import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { Select } from "@ngxs/store";
import { CaseState } from "@vp/data-access/case";
import { CaseTypesState } from "@vp/data-access/case-types";
import { TagsState } from "@vp/data-access/tags";
import { CaseData, CaseType, Tag } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { getValueAtPath } from "@vp/shared/utilities";
import { BehaviorSubject, Observable } from "rxjs";
import { map, take, tap, withLatestFrom } from "rxjs/operators";
@Component({
  selector: "lib-formly-conditon-picker",
  templateUrl: "./formly-condition-picker.component.html",
  styleUrls: ["./formly-condition-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: FormlyConditionPickerComponent }]
})
export class FormlyConditionPickerComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @Select(CaseTypesState.currentCaseType) currentCaseType$!: Observable<CaseType>;
  @Select(CaseState.current) public caseData$!: Observable<CaseData>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;
  @ViewChild("picker") picker: any;

  private readonly _availableTags$ = new BehaviorSubject<Tag[] | null>(null);

  label = "Condition Type";
  noneSelectedText = "";
  availableTags$ = this._availableTags$.asObservable();
  recordData$ = this.caseData$.pipe(map((casedata: CaseData) => casedata.recordData));
  imgName: string | undefined;
  imgSizeXl = false;
  imgSizeLg = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.props.imgSizeXl) {
      this.imgSizeXl = this.props.imgSizeXl;
    }

    if (this.props.imgSizeLg) {
      this.imgSizeLg = this.props.imgSizeLg;
    }

    this.currentCaseType$
      .pipe(
        filterNullMap(),
        take(1),
        withLatestFrom(this.tags$, this.recordData$),
        tap(([casetype, tags, recordData]: [CaseType, Tag[], Record<string, unknown>]) => {
          this.imgName = casetype.friendlyId;
          const values = tags.filter(tag =>
            (tag.tagData?.allowedCaseTypes as string[])?.includes(casetype.caseTypeId)
          );
          const selConditionType = getValueAtPath(recordData, ["subject", "selConditionType"]);
          if (selConditionType) {
            this.formControl.patchValue(selConditionType);
          }
          this._availableTags$.next(values);
        })
      )
      .subscribe();
  }

  getImagePath(): string {
    const basePath = "assets/templates/BNI/";
    const extension = "-icon.png";
    // commenting this out for now and hardcoding here until we are provided
    // the other casetype images
    //return `${basePath}${this.imgName}${extension}`;
    return `${basePath}${"brain-vascular"}${extension}`;
  }
}
