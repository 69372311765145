<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    items: (items$ | async) || [],
    columns: (columns$ | async) || [],
    search: (search$ | async) || null,
    selected: (selected$ | async) || [],
    departments: (departments$ | async) || []
  }"
>
</ng-container>

<ng-template
  #template
  let-items="items"
  let-columns="columns"
  let-search="search"
  let-selected="selected"
  let-departments="departments"
>
  <div flexLayoutAlign="space-between center">
    <div flexLayoutGap="1em">
      <div flex="noshrink" mat-dialog-title>Assign Departments & Roles</div>
      <mat-form-field>
        <mat-label>Departments</mat-label>
        <mat-select
          [formControl]="departmentSelector"
          [multiple]="false"
          #departments
          (selectionChange)="departmentsFilterChanged($event.value)"
        >
          <mat-option *ngFor="let dept of departments" [value]="dept">{{
            dept | department | async
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div flex="50">
        <vp-quick-search
          (changedEvent)="searched($event)"
          aria-placeholder="Filter"
        ></vp-quick-search>
      </div>
    </div>
  </div>

  <div flex flexLayout="column" flexLayoutAlign="space-between">
    <mat-dialog-content flex flexLayout="column">
      <mat-selection-list
        flexFill
        flexLayout="column"
        flexLayoutGap="10px"
        #selectionList
        [multiple]="!singleDepartmentAssignment || !singleRoleAssignment"
        (selectionChange)="selectionChanged($event)"
        class="selection"
      >
        <ng-container *ngIf="items | filterTerm: search:excludeProperties:filters as filtered">
          <mat-list-option
            *ngFor="let item of filtered; trackBy: trackById"
            [value]="item"
            [selected]="isSelected(item) | async"
          >
            <div class="list-row" flexLayout="row" flexLayoutAlign="start center">
              <div flex="40">{{ item.departmentId | department | async }}</div>
              <div flex="40">{{ item.roleId | role | async }}</div>
            </div>
          </mat-list-option>
          <mat-list-item *ngIf="filtered.length === 0">No Assignable Roles</mat-list-item>
        </ng-container>
      </mat-selection-list>
    </mat-dialog-content>

    <mat-dialog-actions flexLayout="row" flexLayoutAlign="end stretch">
      <div>
        <button mat-flat-button [mat-dialog-close] type="button">Cancel</button>
        <button
          mat-raised-button
          color="primary"
          [mat-dialog-close]="selected"
          [disabled]="selected.length === 0"
          (click)="assignSelected()"
          type="button"
        >
          Assign
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</ng-template>
