import { Injectable } from "@angular/core";
import { Observable, of, Subject, throwError } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CacheService<T> {
  private cache: Map<string, CacheContent<T>> = new Map<string, CacheContent<T>>();
  private inFlightObservables: Map<string, Subject<T>> = new Map<string, Subject<T>>();
  readonly DEFAULT_MAX_AGE_MS: number = 30000;

  /**
   * Gets the value from cache if the key is provided.
   * If no value exists in cache, then check if the same call exists
   * in flight, if so return the subject. If not create a new
   * Subject inFlightObservable and return the source observable.
   */
  get(key: string, fallback?: Observable<T>, maxAgeMs?: number): Observable<T> {
    if (this.hasValidCachedValue(key)) {
      const cached = this.cache.get(key);
      if (cached) {
        return of(cached.value);
      }
    }

    if (!maxAgeMs) {
      maxAgeMs = this.DEFAULT_MAX_AGE_MS;
    }

    if (this.inFlightObservables.has(key)) {
      const inFlight = this.inFlightObservables.get(key);
      if (inFlight) {
        return inFlight.asObservable();
      }
    } else if (fallback && fallback instanceof Observable) {
      this.inFlightObservables.set(key, new Subject());
      return fallback.pipe(
        tap(value => {
          this.set(key, value, maxAgeMs);
        })
      );
    }
    return throwError("Key was not found and no fallback provided.");
  }

  set(key: string, value: T, maxAgeMs: number = this.DEFAULT_MAX_AGE_MS): void {
    this.cache.set(key, { value, expiry: Date.now() + maxAgeMs });
    this.notifyInFlightObservers(key, value);
  }

  has(key: string): boolean {
    return this.cache.has(key);
  }

  /**
   * Publishes the value to all observers of the given in progress observables.
   */
  private notifyInFlightObservers(key: string, value: T): void {
    if (this.inFlightObservables.has(key)) {
      const inFlight = this.inFlightObservables.get(key);
      if (!inFlight) return;
      inFlight.next(value);
      inFlight.complete();
      this.inFlightObservables.delete(key);
    }
  }

  private hasValidCachedValue(key: string): boolean {
    if (this.cache.has(key)) {
      const cached = this.cache.get(key);
      if (cached?.expiry && cached.expiry > Date.now()) {
        return true;
      }
    }
    return false;
  }
}

interface CacheContent<T> {
  expiry: number;
  value: T;
}
