import {
  Directive,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  SecurityContext
} from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[formSanitizer]"
})
export class FormSanitizerDirective implements OnInit {
  @Output() formSubmit = new EventEmitter<void>();

  constructor(private domSanitizer: DomSanitizer, private formGroupDirective: FormGroupDirective) {}

  ngOnInit(): void {
    this.sanitizeFormData();
  }

  @HostListener("submit")
  onSubmit(): void {
    this.sanitizeFormData();
  }

  public sanitizeFormData(): void {
    const sanitizedValues: Record<string, unknown> = {};
    const formControls = this.formGroupDirective.form.controls;

    for (const controlName of Object.keys(formControls)) {
      const control = formControls[controlName];
      let isDate = false;

      //Keep string dates intact
      if (control.value instanceof Date) {
        isDate = true;
      }

      if (control.value != null && typeof control.value === "string" && !isDate) {
        const value = (control.value as string) || "";
        const sanitizedValue = this.domSanitizer.sanitize(SecurityContext.HTML, value) || "";
        const cleanValue = sanitizedValue.replace(/<\/?[^>]+(>|$)|(?!\\n)&#[0-9]+;/g, " ");
        sanitizedValues[controlName] = cleanValue;
      } else if (control.value != null && typeof control.value === "object" && !isDate) {
        const sanitizedObject: Record<string, unknown> = {};
        for (const key of Object.keys(control.value)) {
          if (typeof control.value[key] === "string") {
            const value = (control.value[key] as string) || "";
            const sanitizedValue = this.domSanitizer.sanitize(SecurityContext.HTML, value) || "";
            sanitizedObject[key] = sanitizedValue;
          } else {
            sanitizedObject[key] = control.value[key];
          }
        }
        sanitizedValues[controlName] = sanitizedObject;
      } else {
        sanitizedValues[controlName] = control.value;
      }
    }

    return this.formGroupDirective.form.patchValue(sanitizedValues);
  }
}
