import { Operation } from "rfc6902";

export const mergeOperationsById = (existing: Operation[], toMerge: Operation[]) =>
  toMerge.reduce(
    (acc, item) => {
      const existingIndex = acc.findIndex(existing => {
        return existing.op === item.op && existing.path === item.path && item.op === "replace";
      });
      if (existingIndex > -1) {
        acc[existingIndex] = item;
      } else {
        acc.push(item);
      }
      return acc;
    },
    [...existing]
  );
