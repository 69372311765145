import { Message, MessageType } from "@vp/models";

export class GetPacMessages {
  public static readonly type = "[Message] Get Pac Messages";
  constructor(public deviceIds: string[]) {}
}

export class GetTigerConnectMessages {
  public static readonly type = "[Message] Get TigerConnect Messages";
  constructor(public caseId: string) {}
}

export class SendMessages {
  public static readonly type = "[Message] Send Messages";
  constructor(
    public messages: Message[],
    public messageType: MessageType,
    public recipient: string,
    public caseId?: string
  ) {}
}

export class ClearPacMessages {
  public static readonly type = "[Message] Clear PAC Messages";
  constructor(public deviceId: string, public messageTarget: "Patient" | "Nurse") {}
}

export class ResetState {
  public static readonly type = "[Message] Reset State";
}
