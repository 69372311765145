import { Component, OnDestroy } from "@angular/core";
import { AlertItem } from "@vp/shared/components/alert";
import { IndividualConfig } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SystemBannerAdapter } from "./system-banner-adapter.service";

@Component({
  selector: "vp-system-banner",
  templateUrl: "./system-banner.component.html",
  styleUrls: ["./system-banner.component.scss"],
  providers: [SystemBannerAdapter]
})
export class SystemBannerComponent implements OnDestroy {
  alertConfig: Partial<IndividualConfig> = {
    positionClass: "inline",
    tapToDismiss: false,
    closeButton: true,
    disableTimeOut: true,
    enableHtml: true
  };

  private readonly destroyed$ = new Subject();

  constructor(private readonly systemBannerAdapter: SystemBannerAdapter) {}

  banners$ = this.systemBannerAdapter.banners$.pipe(takeUntil(this.destroyed$));

  alertDismissed(alertItem: AlertItem) {
    this.systemBannerAdapter.dismissBanner(alertItem);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
