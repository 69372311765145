import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { GroupsState } from "@vp/data-access/groups";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { UserApiService } from "@vp/data-access/users";
import { Group, GroupRef, GroupType, Tag, TagType, User } from "@vp/models";
import { deeperCopy } from "@vp/shared/utilities";
import { Operation } from "rfc6902";
import { Observable, combineLatest } from "rxjs";
import { map, take } from "rxjs/operators";
import {
  UserAdministrationStateModel,
  defaultUserAdministrationState
} from "../models/user-administration-state.model";
import * as UserAdministrationActions from "./user-administration.actions";

@State<UserAdministrationStateModel>({
  name: "userAdministration",
  defaults: defaultUserAdministrationState()
})
@Injectable()
export class UserAdministrationState {
  constructor(private readonly userApiService: UserApiService, private store: Store) {}

  @Selector()
  static user(state: UserAdministrationStateModel): User | null {
    return deeperCopy(state.user);
  }

  @Selector()
  static getWorkingCopy(state: UserAdministrationStateModel): User | null {
    return state.workingCopy;
  }

  @Selector([UserAdministrationState.getWorkingCopy])
  static workingCopy(user: User): User | null {
    return deeperCopy(user);
  }

  @Selector()
  static assignableGroupTypes(state: UserAdministrationStateModel): GroupType[] {
    return state.assignableGroupTypes;
  }

  @Selector()
  static assignableGroups(state: UserAdministrationStateModel): Group[] {
    return state.assignableGroups;
  }

  @Selector()
  static assignableTagTypes(state: UserAdministrationStateModel): TagType[] {
    return state.assignableTagTypes;
  }

  @Selector()
  static assignableTags(state: UserAdministrationStateModel): Tag[] {
    return state.assignableTags;
  }

  @Selector()
  static getPendingOperations(state: UserAdministrationStateModel): Operation[] {
    return state.pendingOperations ?? [];
  }

  @Selector([UserAdministrationState.getPendingOperations])
  static pendingOperations(pendingOperations: Operation[] | null): Operation[] {
    return pendingOperations ?? [];
  }

  @Action(UserAdministrationActions.ResetState)
  resetState(ctx: StateContext<UserAdministrationStateModel>) {
    ctx.setState(defaultUserAdministrationState());
  }

  @Action(UserAdministrationActions.LoadUser)
  loadUser(
    ctx: StateContext<UserAdministrationStateModel>,
    action: UserAdministrationActions.LoadUser
  ) {
    const organization = this.store.selectSnapshot(OrganizationState.organization);
    if (organization === null) return;

    const user = deeperCopy(action.user);

    ctx.patchState({
      user: user,
      workingCopy: user,
      pendingOperations: []
    });
  }

  @Action(UserAdministrationActions.SetAssignableEntities)
  setAssignableEntities(
    ctx: StateContext<UserAdministrationStateModel>,
    action: UserAdministrationActions.SetAssignableEntities
  ) {
    const workingCopy = ctx.getState().workingCopy;
    if (workingCopy == null) return;
    combineLatest([
      this.getAssignableGroups$(workingCopy, action.assignableGroupTypes),
      this.getAssignableTags$(workingCopy, action.assignableTagTypes)
    ])
      .pipe(take(1))
      .subscribe(([assignableGroups, assignableTags]: [Group[], Tag[]]) => {
        ctx.patchState({
          assignableGroupTypes: action.assignableGroupTypes,
          assignableGroups: assignableGroups,
          assignableTagTypes: action.assignableTagTypes,
          assignableTags: assignableTags
        });
      });
  }

  @Action(UserAdministrationActions.SetUser)
  setUser(
    ctx: StateContext<UserAdministrationStateModel>,
    action: UserAdministrationActions.SetUser
  ) {
    this.userApiService.getUser(action.id, false).subscribe((user: User | null) => {
      if (user) {
        ctx.dispatch(new UserAdministrationActions.LoadUser(user));
      }
    });
  }

  @Action(UserAdministrationActions.UpdateWorkingCopy)
  setWorkingCopy(
    ctx: StateContext<UserAdministrationStateModel>,
    action: UserAdministrationActions.UpdateWorkingCopy
  ) {
    const workingCopy = ctx.getState().workingCopy;
    if (workingCopy != null) {
      const updatedUser: User = { ...workingCopy, ...action.user };
      ctx.patchState({
        workingCopy: updatedUser
      });
    }
  }

  @Action(UserAdministrationActions.SetPendingOperations)
  setPendingOperations(
    ctx: StateContext<UserAdministrationStateModel>,
    action: UserAdministrationActions.SetPendingOperations
  ) {
    ctx.patchState({
      pendingOperations: action.pendingOperations
    });
  }

  private getAssignableGroups$ = (
    user: User,
    assignableGroupTypes: GroupType[]
  ): Observable<Group[]> => {
    const allGroups = this.store.selectSnapshot(GroupsState.allGroups);
    const uniqueAssignmentGroupTypes: string[] = assignableGroupTypes
      .filter((groupType: GroupType) => groupType.uniqueAssignment === true)
      .map((groupType: GroupType) => groupType.groupTypeId);
    const userGroups: string[] = user.groups.map((groupRef: GroupRef) => groupRef.groupId);
    const groupIdsToCheck = allGroups
      .filter(
        (group: Group) =>
          assignableGroupTypes.map(t => t.groupTypeId).includes(group.groupTypeId) &&
          uniqueAssignmentGroupTypes.includes(group.groupTypeId) &&
          !userGroups.includes(group.groupId)
      )
      .map((group: Group) => group.groupId);

    return this.userApiService.getGroupsAssignedToUsers(groupIdsToCheck).pipe(
      map((assignedGroupIds: string[]) => {
        const assignableGroups = allGroups.filter(
          (group: Group) =>
            assignableGroupTypes.map(t => t.groupTypeId).includes(group.groupTypeId) &&
            assignedGroupIds.includes(group.groupId) === false
        );
        return assignableGroups;
      }),
      take(1)
    );
  };

  private getAssignableTags$ = (user: User, assignableTagTypes: TagType[]): Observable<Tag[]> => {
    const tags = this.store.selectSnapshot(TagsState.filtered);
    const uniqueAssigmentTagTypes: string[] = assignableTagTypes
      .filter(t => t.uniqueAssignment === true)
      .map(t => t.tagTypeId);

    const tagsToCheck: Tag[] = tags.filter(
      (tag: Tag) =>
        assignableTagTypes.map(t => t.tagTypeId).includes(tag.tagTypeId) &&
        uniqueAssigmentTagTypes.includes(tag.tagTypeId) &&
        !user.assignedTags.includes(tag.tagId)
    );
    const tagIdsToCheck: string[] = tagsToCheck.map(t => t.tagId);

    return this.userApiService.getTagsAssignedToUsers(tagIdsToCheck).pipe(
      map((assignedTagIds: string[]) => {
        const assignableTags = tags.filter(
          t =>
            assignableTagTypes.map(t => t.tagTypeId).includes(t.tagTypeId) &&
            assignedTagIds.includes(t.tagId) === false
        );
        return assignableTags;
      }),
      take(1)
    );
  };
}
