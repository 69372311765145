import { Injectable, OnDestroy } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { UserApiService } from "@vp/data-access/users";
import { UiSchemaConfigService, UiSchemaLayoutProvider } from "@vp/formly/ui-schema-config";
import {
  AssignableGroupTypes,
  AssignableRoles,
  AssignableTagTypes,
  AssignedRolePerDepartment,
  Department,
  Group,
  GroupRef,
  GroupType,
  LayoutConfigOption,
  Organization,
  Role,
  RoleAccessTagsItem,
  Snippet,
  Tag,
  TagType,
  TagsArray,
  User,
  UserRole,
  UserTypeConfig
} from "@vp/models";
import { NotificationService } from "@vp/shared/notification-service";
import { filterNullMap } from "@vp/shared/operators";
import { deepCopy, deeperCopy, mergeDeep, stringArraysEqual } from "@vp/shared/utilities";
import { JSONSchema7 } from "json-schema";
import { Operation, createPatch } from "rfc6902";
import { EMPTY, Observable, Subject } from "rxjs";
import { concatMap, map, takeUntil, withLatestFrom } from "rxjs/operators";
import * as UserAdministrationActions from "../state+/user-administration.actions";
import { UserAdministrationState } from "../state+/user-administration.state";
import { AssignableRolesService } from "./assignable-roles-service";

@Injectable()
export class UserAdministrationService implements OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(OrganizationState.groupTypes) groupTypes$!: Observable<GroupType[]>;
  @Select(UserAdministrationState.user) user$!: Observable<User | null>;
  @Select(UserAdministrationState.workingCopy) workingCopy$!: Observable<User | null>;
  @Select(UserAdministrationState.assignableTags) userAssignableTags$!: Observable<Tag[]>;
  @Select(UserAdministrationState.pendingOperations) pendingOperations$!: Observable<Operation[]>;
  private readonly _destroyed$ = new Subject();

  constructor(
    private readonly store: Store,
    private readonly userApiService: UserApiService,
    private assignableRolesService: AssignableRolesService,
    private readonly uiSchemaConfigService: UiSchemaConfigService,
    private readonly notificationService: NotificationService,
    private readonly uiSchemaLayoutProvider: UiSchemaLayoutProvider
  ) {}

  initalize() {
    this.workingCopy$
      .pipe(
        withLatestFrom(this.organization$.pipe(filterNullMap())),
        takeUntil(this._destroyed$),
        map(([user, organization]: [User | null, Organization]) => {
          if (user == null) return;
          const assignableGroupTypes: GroupType[] = this.getAssignableGroupTypes(
            user,
            organization.departments,
            organization.groupTypes
          );
          const assignableTagTypes: TagType[] = this.getAssignableTagTypes(
            user,
            organization.departments,
            organization.tagTypes
          );

          this.store.dispatch(
            new UserAdministrationActions.SetAssignableEntities(
              assignableGroupTypes,
              assignableTagTypes
            )
          );
        })
      )
      .subscribe();
  }

  getAssignableGroupTypes = (
    user: User,
    departments: Department[],
    groupTypes: GroupType[]
  ): GroupType[] => {
    const userDepartmentIds: Set<string> = new Set<string>();
    user.roles.forEach(r => r.departments.forEach(d => userDepartmentIds.add(d.departmentId)));
    const userDepartments: Department[] = departments.filter(d =>
      userDepartmentIds.has(d.departmentId)
    );
    const assignableGroupTypeFriendlyIds: Set<string> = new Set<string>();
    userDepartments.forEach((dept: Department) =>
      dept.assignableGroupTypes
        .filter(
          (assignableGroupTypes: AssignableGroupTypes) =>
            assignableGroupTypes.userType === user.userType.friendlyId
        )
        .forEach((assignableGroupTypes: AssignableGroupTypes) =>
          assignableGroupTypes.groupTypes?.forEach((groupTypeFriendlyId: string) =>
            assignableGroupTypeFriendlyIds.add(groupTypeFriendlyId)
          )
        )
    );

    return groupTypes.filter((groupType: GroupType) =>
      assignableGroupTypeFriendlyIds.has(groupType.friendlyId)
    );
  };

  getAssignableTagTypes = (
    user: User,
    departments: Department[],
    tagTypes: TagType[]
  ): TagType[] => {
    const userDepartmentIds: Set<string> = new Set<string>();
    user.roles.forEach(r => r.departments.forEach(d => userDepartmentIds.add(d.departmentId)));
    const userDepartments: Department[] = departments.filter(d =>
      userDepartmentIds.has(d.departmentId)
    );
    const assignableTagTypeFriendlyIds: Set<string> = new Set<string>();
    userDepartments.forEach(d =>
      d.assignableTagTypes
        .filter(
          (assignableTagTypes: AssignableTagTypes) =>
            assignableTagTypes.userType === user.userType.friendlyId
        )
        .forEach((assignableTagTypes: AssignableTagTypes) =>
          assignableTagTypes.tagTypes?.forEach((tagTypeFriendlyId: string) =>
            assignableTagTypeFriendlyIds.add(tagTypeFriendlyId)
          )
        )
    );

    return tagTypes.filter((tagType: TagType) =>
      assignableTagTypeFriendlyIds.has(tagType.friendlyId)
    );
  };

  layoutSchema$ = (userTypeFriendlyId: string): Observable<JSONSchema7> => {
    const organization = this.store.selectSnapshot(OrganizationState.organization);
    if (!organization) return EMPTY;

    const userTypeConfig: UserTypeConfig | undefined = organization.userTypeConfig.find(
      c => c.type == userTypeFriendlyId
    );
    if (userTypeConfig === undefined) return EMPTY;
    const layoutConfig: LayoutConfigOption = userTypeConfig.userLayout;
    this.uiSchemaConfigService.addScopedConfig(
      layoutConfig,
      `user-administration-${userTypeFriendlyId}`
    );
    const schema: JSONSchema7 = userTypeConfig.userSchema;
    return this.uiSchemaLayoutProvider.applyScopes(
      "userAdminComponent",
      schema,
      `user-administration-${userTypeFriendlyId}`
    );
  };

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.resetUser();
  }

  resetUser() {
    this.store.dispatch(new UserAdministrationActions.ResetState());
  }

  setUser(id: string) {
    this.store.dispatch(new UserAdministrationActions.SetUser(id));
  }

  loadUser(user: User) {
    this.store.dispatch(new UserAdministrationActions.LoadUser(user));
  }

  commit() {
    const userId = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy)?.userId;
    const pendingOperations = this.store.selectSnapshot(UserAdministrationState.pendingOperations);

    if (!userId || pendingOperations.length === 0) return EMPTY;

    return this.userApiService.patch(userId, pendingOperations, "user_adminUserSave").pipe(
      concatMap(() => {
        return this.store.dispatch(new UserAdministrationActions.SetUser(userId));
      })
    );
  }

  invite(resendInvite?: boolean) {
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);
    if (!workingCopy) {
      return EMPTY;
    }

    if (!workingCopy.roles || workingCopy.roles.length === 0) {
      throw Error(
        "Failed to resend invitation. Please assign one or more department & role(s) to User"
      );
    }

    return this.userApiService.inviteUser(workingCopy, resendInvite);
  }

  create() {
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);
    if (!workingCopy) {
      return EMPTY;
    }

    if (!workingCopy.roles || workingCopy.roles.length === 0) {
      throw Error(
        `Please assign one or more department & role(s) to ${workingCopy.userType.displayName}`
      );
    }

    return this.userApiService.createUser(workingCopy);
  }

  clone(userId: string) {
    this.userApiService
      .getUser(userId)
      .pipe(
        filterNullMap(),
        withLatestFrom(
          this.assignableRolesService.roleWithConditionalAccess$,
          this.assignableRolesService.allowedRolesPerDepartment$
        )
      )
      .subscribe(
        ([userCloningFrom, assignableRoles, assignedRoles]: [
          User,
          AssignableRoles | null,
          AssignedRolePerDepartment[]
        ]) => {
          if (!assignableRoles) {
            if (userCloningFrom.roles?.length !== 0) {
              this.updateWorkingCopy({
                roles: userCloningFrom.roles,
                assignedTags: userCloningFrom.assignedTags
              });
            } else {
              this.notificationService.warningMessage(
                "No roles available to clone based on your Roles permitted"
              );
            }
          } else {
            this.updateWorkingCopy({
              roles: userCloningFrom.roles.filter(x =>
                assignedRoles.map(y => y.roleId).includes(x.roleId)
              ),
              assignedTags: userCloningFrom.assignedTags
            });
          }
        }
      );
  }

  updateWorkingCopy = (partialUser: Partial<User>) => {
    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);
    const modified = mergeDeep(workingCopy, partialUser, "replace", true);

    if (!original || !workingCopy) return;

    const operations = createPatch(original, modified);

    this.store.dispatch(new UserAdministrationActions.UpdateWorkingCopy(modified));
    this.store.dispatch(new UserAdministrationActions.SetPendingOperations(operations));
  };

  addSingleDepartmentRole(role: AssignedRolePerDepartment) {
    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);
    const organization = this.store.selectSnapshot(OrganizationState.organization);

    if (!original || !workingCopy || !organization) return;

    const modified = addDepartmentRole(
      workingCopy,
      role.roleId,
      role.departmentId,
      organization.roles,
      organization.departments
    );

    const operations = createPatch(original, modified);
    this.store.dispatch(new UserAdministrationActions.UpdateWorkingCopy(modified));
    this.store.dispatch(new UserAdministrationActions.SetPendingOperations(operations));
  }

  addDepartmentRoles(roles: AssignedRolePerDepartment[]) {
    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);
    const organization = this.store.selectSnapshot(OrganizationState.organization);

    if (!original || !workingCopy || !organization) return;

    const modified = addDepartmentRoles(
      workingCopy,
      roles,
      organization.roles,
      organization.departments
    );

    const operations = createPatch(original, modified);
    this.store.dispatch(new UserAdministrationActions.UpdateWorkingCopy(modified));
    this.store.dispatch(new UserAdministrationActions.SetPendingOperations(operations));
  }

  deleteDepartmentRole(roleId: string, departmentId: string) {
    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);

    if (!original || !workingCopy) return;

    const modified = deleteDepartmentByRole(workingCopy, roleId, departmentId);
    const operations = createPatch(original, modified);

    this.store.dispatch(new UserAdministrationActions.UpdateWorkingCopy(modified));
    this.store.dispatch(new UserAdministrationActions.SetPendingOperations(operations));
  }

  addGroups(groups: Group[]) {
    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);

    if (!original || !workingCopy) return;

    const modified = addGroups(workingCopy, groups);
    const operations = createPatch(original, modified);

    this.store.dispatch(new UserAdministrationActions.UpdateWorkingCopy(modified));
    this.store.dispatch(new UserAdministrationActions.SetPendingOperations(operations));
  }

  deleteGroups(groupIds: string[]) {
    if (groupIds.length === 0) return;

    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);

    if (!original || !workingCopy) return;

    const modified = deleteGroups(workingCopy, groupIds);
    const operations = createPatch(original, modified);

    this.store.dispatch(new UserAdministrationActions.UpdateWorkingCopy(modified));
    this.store.dispatch(new UserAdministrationActions.SetPendingOperations(operations));
  }

  addAccessTags(roleId: string, tagIds: string[], compoundAssignmentEnabled: boolean) {
    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);

    if (!original || !workingCopy) return;

    const modified = addAccessTags(workingCopy, roleId, tagIds, compoundAssignmentEnabled);
    const operations = createPatch(original, modified);

    this.store.dispatch(new UserAdministrationActions.UpdateWorkingCopy(modified));
    this.store.dispatch(new UserAdministrationActions.SetPendingOperations(operations));
  }

  deleteAccessTags(item: RoleAccessTagsItem) {
    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);

    if (!original || !workingCopy) return;

    const modified = deleteUserRoleAccessTags(workingCopy, item);
    const operations = createPatch(original, modified);

    this.store.dispatch(new UserAdministrationActions.UpdateWorkingCopy(modified));
    this.store.dispatch(new UserAdministrationActions.SetPendingOperations(operations));
  }

  addOrEditSnippet(action: "Add" | "Edit", snippet: Snippet, index?: number) {
    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);

    if (!original || !workingCopy) return;

    const modified = addOrEditSnippet(workingCopy, action, snippet, index);
    const operations = createPatch(original, modified);

    this.store.dispatch(new UserAdministrationActions.UpdateWorkingCopy(modified));
    this.store.dispatch(new UserAdministrationActions.SetPendingOperations(operations));
  }

  deleteSnippet(index: number) {
    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);

    if (!original || !workingCopy) return;

    const modified = deleteSnippet(workingCopy, index);
    const operations = createPatch(original, modified);

    this.store.dispatch(new UserAdministrationActions.UpdateWorkingCopy(modified));
    this.store.dispatch(new UserAdministrationActions.SetPendingOperations(operations));
  }
}

const addDepartmentRole = (
  user: User,
  userRoleId: string,
  userDepartmentId: string,
  roles: Role[],
  departments: Department[]
) => {
  const copy = deeperCopy(user);
  const role = copy.roles.find((role: Role) => role.roleId === userRoleId);
  const deptFriendlyId = departments.find(d => d.departmentId === userDepartmentId)?.friendlyId;
  if (role) {
    role.departments.push({
      departmentId: userDepartmentId,
      friendlyId: deptFriendlyId
    });
  } else {
    const roleFriendlyId = roles.find(r => r.roleId === userRoleId)?.friendlyId;
    if (!roleFriendlyId) return;
    copy.roles.push({
      roleId: userRoleId,
      friendlyId: roleFriendlyId,
      departments: [
        {
          departmentId: userDepartmentId,
          friendlyId: deptFriendlyId
        }
      ]
    });
  }
  return copy;
};

const addDepartmentRoles = (
  user: User,
  userRoles: AssignedRolePerDepartment[],
  roles: Role[],
  departments: Department[]
) => {
  return userRoles.reduce(
    (acc: User, currRole: AssignedRolePerDepartment) =>
      addDepartmentRole(acc, currRole.roleId, currRole.departmentId, roles, departments),
    user
  );
};

const addAccessTags = (
  user: User,
  roleId: string,
  tagIds: string[],
  compoundAssignmentEnabled: boolean
): User => {
  const copy = deeperCopy(user);
  const userRole: UserRole = copy.roles.find((r: UserRole) => r.roleId === roleId);
  const tagsArray: TagsArray = { tags: tagIds };
  if (!userRole) throw Error("User has no roles.");
  if (!Array.isArray(userRole.accessTags)) {
    userRole["accessTags"] = [];
  }
  if (compoundAssignmentEnabled) {
    userRole.accessTags.push(tagsArray);
  } else {
    tagIds.forEach(tagId => {
      userRole.accessTags?.push({ tags: [tagId] });
    });
  }

  return copy;
};

const deleteDepartmentByRole = (user: User, roleId: string, departmentId: string) => {
  const copy: User = deepCopy(user);
  const role = copy.roles.find(r => r.roleId === roleId);
  if (role) {
    role.departments = role?.departments.filter(d => d.departmentId !== departmentId);
    if (role.departments.length === 0) {
      copy.roles = copy.roles.filter(r => r.roleId !== roleId);
    }
  }
  return copy;
};

const deleteUserRoleAccessTags = (user: User, item: RoleAccessTagsItem) => {
  const copy: User = deepCopy(user);
  const role: UserRole | undefined = copy.roles.find(r => r.roleId === item.roleId);
  if (role) {
    const accessTags: TagsArray | undefined = role.accessTags?.find(accessTags =>
      stringArraysEqual(accessTags.tags, item.tagIds)
    );
    if (accessTags) {
      role.accessTags = role.accessTags?.filter(
        accessTags => stringArraysEqual(accessTags.tags, item.tagIds) === false
      );
    }
  }
  return copy;
};

const addGroups = (user: User, groups: Group[]) => {
  const copy: User = deepCopy(user);
  copy.groups = copy.groups.concat(
    groups.map(group => {
      return {
        groupId: group.groupId,
        groupTypeId: group.groupTypeId
      } as GroupRef;
    })
  );
  return copy;
};

const deleteGroups = (user: User, groupIds: string[]) => {
  const copy: User = deepCopy(user);
  copy.groups = copy.groups.filter(g => groupIds.includes(g.groupId));
  return copy;
};

const addOrEditSnippet = (
  user: User,
  action: "Add" | "Edit",
  snippet: Snippet,
  index?: number
): User => {
  const copy: User = deepCopy(user);
  if (!copy.userData) return copy;

  switch (action) {
    case "Add":
      copy.userData = {
        ...copy.userData,
        snippets: copy.userData?.snippets ? copy.userData?.snippets?.concat(snippet) : [snippet]
      };
      break;

    case "Edit":
      if (index !== undefined && copy.userData.snippets) {
        copy.userData.snippets[index] = snippet;
      }
      break;
  }
  return copy;
};

const deleteSnippet = (user: User, index: number) => {
  const copy: User = deepCopy(user);
  if (!copy.userData) return copy;
  if (copy.userData.snippets) {
    copy.userData.snippets.splice(index, 1);
  }
  return copy;
};
