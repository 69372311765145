import { Injectable, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import {
  AssignmentModalOptions,
  ContentData,
  OrganizationContentType,
  Tag,
  TagType
} from "@vp/models";
import { IAssignmentService } from "@vp/shared/assignments/models";
import { Observable, combineLatest } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { ContentFilterState } from "../state+/content-filter.state";

@Injectable()
export class ContentTagsAssignmentService implements IAssignmentService {
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;
  @Select(ContentFilterState.workingCopy) workingCopy$!: Observable<ContentData>;
  @Select(OrganizationState.tagTypes) tagTypes$!: Observable<TagType[]>;
  @Select(OrganizationState.contentTypes) contentTypes$!: Observable<OrganizationContentType[]>;

  constructor(private matDialog: MatDialog) {}

  assignedTagIds$ = this.workingCopy$.pipe(map((c: ContentData) => c?.tags ?? []));

  assignableEntities$ = combineLatest([this.assignedTagIds$, this.tags$]).pipe(
    map(([assigned, all]: [string[], Tag[]]) => all.filter(tag => assigned.indexOf(tag.tagId) < 0))
  );

  assignableTagTypes$ = this.workingCopy$.pipe(
    withLatestFrom(this.tagTypes$, this.contentTypes$),
    map(
      ([contentData, tagTypes, contentTypes]: [
        ContentData,
        TagType[],
        OrganizationContentType[]
      ]) => getAssignableTagTypes(contentData, tagTypes, contentTypes)
    )
  );

  public open(templateRef: TemplateRef<any>, options: AssignmentModalOptions) {
    const dialogConfig = {
      ...options.config,
      data: {
        ...options.data
      }
    };
    const dialogRef = this.matDialog.open(templateRef, dialogConfig);
    return dialogRef.afterClosed();
  }
}

const getAssignableTagTypes = (
  contentData: ContentData,
  tagTypes: TagType[],
  contentTypes: OrganizationContentType[]
) => {
  const selectedContentType = contentTypes.find(ct => ct.friendlyId === contentData.contentTypeId);
  if (selectedContentType) {
    let assignableTagTypes = tagTypes;
    if (selectedContentType.assignableTagTypesToContent?.length > 0) {
      assignableTagTypes = tagTypes.filter((type: TagType) =>
        selectedContentType.assignableTagTypesToContent.includes(type.friendlyId)
      );
    }
    return assignableTagTypes;
  }
  return [];
};
