<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    tags: (tags$ | async) ?? [],
    selected: (selectedTags$ | async) || [],
    columns: (displayedColumns$ | async) || [],
    search: (searchTerm$ | async) || null,
    infoMessage: infoMessage$ | async,
    selectedTagsDisplay: (selectedTagsDisplay$ | async) || {},
    assignableTagTypes: (assignableTagTypes$ | async) || []
  }"
>
</ng-container>

<ng-template
  #template
  let-tags="tags"
  let-columns="columns"
  let-search="search"
  let-selected="selected"
  let-infoMessage="infoMessage"
  let-selectedTagsDisplay="selectedTagsDisplay"
  let-assignableTagTypes="assignableTagTypes"
>
  <div flexLayoutAlign="space-between center">
    <div flexLayoutGap="1em">
      <div flex="noshrink" mat-dialog-title>{{ title }}</div>
      <vp-tag-type-selector
        #tagTypeSelector
        [multiple]="false"
        [availableTagTypes]="assignableTagTypes"
        (changedEvent)="tagTypeFilterChanged($event)"
      >
      </vp-tag-type-selector>
      <div flex="50">
        <vp-quick-search
          (changedEvent)="searched($event)"
          aria-placeholder="Filter"
        ></vp-quick-search>
      </div>
    </div>
  </div>

  <div flex flexLayout="column" flexLayoutAlign="space-between">
    <mat-dialog-content flex flexLayout="column">
      <ng-container *ngIf="infoMessage; else tableContent"
        ><div flexLayout="column" flexLayoutAlign="center center" flex="100" flexLayoutGap="12px">
          <div>{{ infoMessage }}</div>
          <button
            mat-flat-button
            color="primary"
            (click)="goToTagList(tagTypeSelector.formControl)"
          >
            Go to Tag List
          </button>
        </div>
      </ng-container>
      <ng-template #tableContent>
        <ng-container *ngIf="tags | filterTerm: search:excludeProperties as filteredTags">
          <table mat-table #assignedTable [dataSource]="filteredTags">
            <ng-container *ngFor="let column of columns; let i = index">
              <ng-container
                *ngIf="column.field !== 'actions'; else actions"
                [matColumnDef]="column.field"
              >
                <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
                <td mat-cell *matCellDef="let item">
                  <span *ngIf="!column.pipe; else piped">{{ item[column.field] }}</span>
                  <ng-template #piped>
                    <span *ngIf="column.pipe">{{
                      item[column.field] | dynamicLookup: column.pipe:item[column.field] | async
                    }}</span>
                  </ng-template>
                </td>
              </ng-container>
              <ng-template #actions>
                <ng-container matColumnDef="actions" stickyEnd="true">
                  <th mat-header-cell *matHeaderCellDef>Select</th>
                  <td mat-cell *matCellDef="let item">
                    <mat-icon class="selected" aria-hidden="false" *ngIf="isSelected(item) | async">
                      done
                    </mat-icon>
                  </td>
                </ng-container>
              </ng-template>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns | mapToField: 'field'"></tr>
            <tr
              mat-row
              *matRowDef="let item; columns: columns | mapToField: 'field'"
              (click)="selectionChanged(item)"
            ></tr>
            <tr class="mat-row" *matNoDataRow>
              <td
                class="mat-cell"
                [attr.colspan]="columns.length"
                *ngIf="filteredTags.length === 0 && selectedTagTypes.length > 0"
              >
                No tags found
              </td>
            </tr>
          </table>
        </ng-container>
      </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions flexLayout="row" flexLayoutAlign="space-between center">
      <div>
        <div>Selected Tags:</div>
        <div *ngFor="let tag of selectedTagsDisplay | keyvalue: originalOrder">
          <b>{{ tag.key }}:</b>
          <span *ngFor="let displayName of $any(tag.value); let last = last">
            {{ displayName }}{{ last ? "" : ", " }}
          </span>
        </div>
      </div>
      <div flexLayout="row" flexLayoutAlign="end">
        <button mat-flat-button [mat-dialog-close] type="button">Cancel</button>
        <button
          mat-raised-button
          color="primary"
          [mat-dialog-close]="selected"
          [disabled]="selected.length === 0"
          (click)="assignSelected()"
          type="button"
        >
          Assign
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</ng-template>
