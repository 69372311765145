import { Component, ChangeDetectionStrategy } from "@angular/core";
import { FieldType, FormlyFieldConfig } from "@ngx-formly/core";

@Component({
  selector: "lib-formly-flex-layout-type",
  templateUrl: "./formly-flex-layout-type.component.html",
  styleUrls: ["./formly-flex-layout-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFlexLayoutTypeComponent extends FieldType {
  flexDefault = "100";

  getFlex(field: FormlyFieldConfig): string {
    if (field?.props?.flex) {
      return field?.props?.flex;
    }
    return this.flexDefault;
  }

  trackById(index: number, item: FormlyFieldConfig) {
    return item.id ?? index;
  }
}
