import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouterModule } from "@angular/router";
import { FormlyModule } from "@ngx-formly/core";
import { NgxsModule } from "@ngxs/store";
import { UserAssignRolesModule } from "@vp/administration/ui/user-assign-roles";
import { UserAssignedRolesModule } from "@vp/administration/ui/user-assigned-roles";
import { UserAssignedTagsModule } from "@vp/administration/ui/user-assigned-tags";
import { ApplicationState } from "@vp/data-access/application";
import { RequestMetaDataResolver } from "@vp/data-access/request-meta-data";
import { TagsDataAccessModule, TagsState } from "@vp/data-access/tags";
import { ModalWrapperComponent } from "@vp/formly/json-schema";
import { FormlyMultiTagSelectorComponent } from "@vp/shared/components/multi-tag-selector";
import { PhoneInputModule } from "@vp/shared/components/phone-input";
import { FlexModule } from "@vp/shared/directives/flex";
import { FormSanitizerModule } from "@vp/shared/directives/form-sanitizer";
import { FeaturesModule } from "@vp/shared/features";
import { UserAdministrationState } from "@vp/user-administration/data-access/user-administration-state";
import { UserBioModule } from "@vp/user-profile/ui/user-bio";
import { UserESignatureModule } from "@vp/user-profile/ui/user-eSignature";
import {
  UserSearchAlertWrapperComponent,
  UserSearchAlertWrapperModule
} from "@vp/user-profile/ui/user-search-alert-wrapper";
import { UserSnippetsFeatureModule } from "@vp/user-snippets/feature";
import { UserSnippetsModule } from "@vp/user-snippets/ui/user-snippets";
import { NgxMaskModule } from "ngx-mask";
import { NgxPermissionsModule } from "ngx-permissions";
import { UserProfileShellComponent } from "./user-profile-shell.component";
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxsModule.forFeature([UserAdministrationState, ApplicationState, TagsState]),
    UserSearchAlertWrapperModule,
    FormlyModule.forChild({
      types: [
        {
          name: "multitagselector",
          component: FormlyMultiTagSelectorComponent
        },
        {
          name: "modal-wrapper",
          component: ModalWrapperComponent
        },
        {
          name: "user-search-alert-wrapper",
          component: UserSearchAlertWrapperComponent
        }
      ]
    }),
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxMaskModule.forRoot({ validation: true }),
    NgxPermissionsModule.forChild(),
    FormSanitizerModule,
    FeaturesModule,
    PhoneInputModule,
    TagsDataAccessModule,
    UserAssignedTagsModule,
    UserAssignedRolesModule,
    UserAssignRolesModule,
    UserSnippetsFeatureModule,
    UserSnippetsModule,
    UserESignatureModule,
    UserBioModule,
    FlexModule
  ],
  exports: [UserProfileShellComponent],
  declarations: [UserProfileShellComponent],
  providers: [RequestMetaDataResolver]
})
export class UserProfileShellModule {}
