import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { Select, Store } from "@ngxs/store";
import { UserAssignTagsComponent } from "@vp/administration/ui/user-assign-tags";
import { ApplicationState } from "@vp/data-access/application";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState, getChildTags } from "@vp/data-access/tags";
import {
  AssignableRoles,
  Column,
  DialogueNames,
  Organization,
  Tag,
  TagType,
  User
} from "@vp/models";
import {
  DialogData,
  DialogFactoryService,
  GenericDialog
} from "@vp/shared/components/generic-dialog";
import { SharedConfirmationService } from "@vp/shared/confirmation";
import { NotificationService } from "@vp/shared/notification-service";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { TagTypePathPipe, TagWithParentsPipe } from "@vp/shared/pipes/context-display";
import { sortItems } from "@vp/shared/utilities";
import {
  AssignableRolesService,
  UserAdministrationService
} from "@vp/user-administration/data-access/user-administration-state";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, EMPTY, Observable, Subject, combineLatest, of, zip } from "rxjs";
import { concatMap, filter, map, mergeMap, switchMap, take, takeUntil } from "rxjs/operators";

export interface AssignedTagViewModel {
  tagType: string;
  tag: string;
  tagId: string;
  tagTypeFriendlyId: string;
}

@Component({
  selector: "vp-user-assigned-tags",
  templateUrl: "./user-assigned-tags.component.html",
  styleUrls: ["./user-assigned-tags.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TagTypePathPipe, TagWithParentsPipe]
})
export class UserAssignedTagsComponent implements OnInit {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(TagsState.tags) tags$!: BehaviorSubject<Tag[]>;

  @ViewChild("modalTemplate", { static: false })
  modalTemplate!: TemplateRef<UserAssignTagsComponent>;

  private readonly destroyed$ = new Subject();
  private readonly displayedColumnsSubject$ = new BehaviorSubject<Column[]>([]);
  private readonly sortSubject$ = new BehaviorSubject<Sort>({
    direction: "asc",
    active: "tag"
  });

  private mobileUserColumns: Column[] = [
    {
      field: "tag",
      header: "Name"
    }
  ];
  private desktopUserColumns: Column[] = [
    {
      field: "tagTypeFriendlyId",
      header: "Type",
      pipe: TagTypePathPipe
    },
    {
      field: "tagId",
      header: "Name",
      pipe: TagWithParentsPipe
    }
  ];
  dialog!: GenericDialog;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private confirmationDialog: SharedConfirmationService,
    private dialogFactoryService: DialogFactoryService,
    private ngxPermissionsService: NgxPermissionsService,
    private notificationService: NotificationService,
    private assignableRolesService: AssignableRolesService,
    private readonly store: Store,
    private userAdministrationService: UserAdministrationService,
    public permConst: PermissionsConstService
  ) {}

  assignedTags$: Observable<AssignedTagViewModel[]> =
    this.userAdministrationService.workingCopy$.pipe(
      filterNullMap(),
      mergeMap((user: User) => {
        return combineLatest([
          of(user.assignedTags ?? []),
          this.tags$.pipe(filter(tags => tags.length > 0)),
          this.organization$
        ]);
      }),
      map(([tagIds, tags, org]: [string[], Tag[], Organization]) => {
        return mapToViewModels(tagIds, tags, org);
      })
    );

  displayedColumns$ = this.displayedColumnsSubject$.pipe(
    switchMap(c => {
      return combineLatest([
        of([...c]),
        this.ngxPermissionsService.hasPermission([this.permConst.Admin.User.TagsAssignment.Delete])
      ]);
    }),
    map(([columns, hasWritePermissions]: [Column[], boolean]) => {
      if (hasWritePermissions) {
        columns.push({
          field: "actions",
          header: "Delete"
        } as Column);
      }
      return columns;
    })
  );

  availableAccessTags$(tagVm: AssignedTagViewModel): Observable<boolean> {
    return this.assignableRolesService.assignableRole$.pipe(
      take(1),
      map((assignableRoles: AssignableRoles[] | null) => {
        if (assignableRoles === null) {
          return true;
        }
        const user = this.store.selectSnapshot(ApplicationState.loggedInUser);
        return user?.assignedTags.includes(tagVm.tagId) || false;
      })
    );
  }

  ngOnInit(): void {
    this.userAdministrationService.initalize();

    this.breakpointObserver
      .observe(["(max-width: 1050px)"])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.displayedColumnsSubject$.next(this.mobileUserColumns);
        } else {
          this.displayedColumnsSubject$.next(this.desktopUserColumns);
        }
      });
  }

  private confirmRemove(dialogueName: string, tagName: string) {
    return this.confirmationDialog
      .open(
        `You are about to remove "${tagName}" and any child tags associated with it`,
        "Remove",
        "Are you sure?",
        dialogueName
      )
      .afterConfirmedOrSkipped();
  }

  assignHandler = () => {
    this.openDialog({
      title: "Assign Tags",
      template: this.modalTemplate
    });
  };

  unassignHandler = (tagVm: AssignedTagViewModel) => {
    this.confirmRemove(DialogueNames.RemoveTagConfirmation, tagVm.tag)
      .pipe(
        concatMap((confirmed: boolean) => {
          if (confirmed) {
            return zip(
              this.userAdministrationService.workingCopy$.pipe(filterNullMap()),
              this.tags$
            );
          } else {
            return EMPTY;
          }
        }),
        take(1)
      )
      .subscribe({
        next: ([workingCopy, allTags]: [User, Tag[]]) => {
          const tag = allTags.find(t => t.tagId == tagVm.tagId);
          if (tag) {
            const assignedTags = allTags.filter(t => workingCopy.assignedTags.includes(t.tagId));
            const childTagIds = getChildTags(tag, assignedTags).map(t => t.tagId);
            const tagIds = workingCopy.assignedTags.filter(
              tagId => tagId !== tag.tagId && !childTagIds.includes(tagId)
            );
            this.userAdministrationService.updateWorkingCopy({
              assignedTags: tagIds
            });
          }
        },
        error: () => {
          this.notificationService.errorMessage(`Failed to remove ${tagVm.tag}`);
        }
      });
  };

  tagsSortHandler = (sort: Sort): void => {
    this.sortSubject$.next(sort);
  };

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData, {
      width: "70vw",
      disableClose: false
    });
    this.dialog.closed$.pipe(take(1)).subscribe();
  }
}
const mapToViewModels = (
  tagIds: string[],
  tags: Tag[],
  org: Organization
): AssignedTagViewModel[] => {
  const viewModels: AssignedTagViewModel[] = [];
  tagIds.forEach((tagId: string) => {
    const tag: Tag | undefined = tags.find(t => t.tagId == tagId);
    if (tag !== undefined) {
      const tagType: TagType | undefined = org.tagTypes.find(t => t.tagTypeId == tag.tagTypeId);
      viewModels.push({
        tagType: tagType?.displayName ?? "[Tag Type not found]",
        tag: tag?.displayName ?? "[Tag not found]",
        tagId: tagId,
        tagTypeFriendlyId: tagType?.friendlyId ?? ""
      });
    } else {
      viewModels.push({
        tagType: "[Tag not found]",
        tag: "[Tag not found]",
        tagId: tagId,
        tagTypeFriendlyId: ""
      });
    }
  });
  return sortItems(viewModels, ["tagType", "tag"], "asc");
};
