import { Injectable, OnDestroy } from "@angular/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import {
  AssignableRoles,
  AssignedRolePerDepartment,
  Department,
  DepartmentRole,
  Organization,
  OrganizationFeatures,
  Role,
  User,
  UserRole
} from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { Observable, Subject, combineLatest } from "rxjs";
import { map, switchMap, withLatestFrom } from "rxjs/operators";
import { UserAdministrationState } from "../state+/user-administration.state";

@Injectable({
  providedIn: "root"
})
export class AssignableRolesService implements OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(UserAdministrationState.user) user$!: Observable<User | null>;
  @Select(UserAdministrationState.workingCopy) workingCopy$!: Observable<User | null>;

  private readonly _destroyed$ = new Subject();

  constructor(private featureService: FeatureService, private appStore: AppStoreService) {}

  assignableRole$ = combineLatest([
    this.appStore.selectedRole,
    this.featureService.featureEnabled$("assignableRoles"),
    this.featureService.configurationDictionaries$("assignableRoles")
  ]).pipe(
    map(([role, enabled, config]: [Role, boolean, Record<string, unknown>]) => {
      return enabled ? <AssignableRoles[]>config[role.friendlyId] || null : null;
    })
  );

  roleWithConditionalAccess$ = this.assignableRole$.pipe(
    withLatestFrom(this.appStore.selectedRole),
    map(([assignableRoles, selectedRole]: [AssignableRoles[] | null, Role]) => {
      const matchingRole = assignableRoles
        ? assignableRoles.find(x => x.roleFriendlyId == selectedRole.friendlyId) || null
        : null;
      return matchingRole;
    })
  );

  allowedRolesPerDepartment$ = this.featureService
    .featureEnabled$(OrganizationFeatures.allRolesAssignable)
    .pipe(
      withLatestFrom(
        this.assignableRole$,
        this.featureService.configurationLists$(OrganizationFeatures.allRolesAssignable),
        this.appStore.selectedRole,
        this.workingCopy$.pipe(filterNullMap())
      ),
      switchMap(
        ([featureEnabled, assignableRoles, lists, selectedRole, workingCopy]: [
          boolean,
          AssignableRoles[] | null,
          Record<string, string[]>,
          Role,
          User
        ]) => {
          const matchingRole = assignableRoles
            ? assignableRoles.find(x => x.roleFriendlyId == selectedRole.friendlyId) || null
            : null;
          const roles: string[] = lists["roles"];
          if (
            (roles.includes(selectedRole.friendlyId) && featureEnabled) ||
            (matchingRole && matchingRole.enabled)
          ) {
            return this.selectedRoles$(matchingRole, workingCopy);
          }
          return this.appStore.user$.pipe(map(user => mapToVm(user.roles)));
        }
      )
    );

  roleConfiguration$ = this.organization$.pipe(
    filterNullMap(),
    withLatestFrom(this.assignableRole$, this.appStore.selectedRole),
    map(([org, assignableRole, role]: [Organization, AssignableRoles[] | null, Role]) => {
      const matchingRole = assignableRole
        ? assignableRole.find(x => x.roleFriendlyId == role.friendlyId) || null
        : null;
      return matchingRole ? org.userTypeConfig.find(ut => ut.type == matchingRole?.userType) : null;
    })
  );

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private selectedRoles$(matchingRole: AssignableRoles | null, workingCopy: User) {
    return this.organization$.pipe(
      filterNullMap(),
      map((org: Organization) => {
        const assignableRoles = matchingRole
          ? org.userTypeConfig.find(ut => ut.type == matchingRole?.userType)
              ?.assignableRoleFriendlyId
          : org.userTypeConfig.find(ut => ut.type == workingCopy.userType.friendlyId)
              ?.assignableRoleFriendlyId;
        const models: AssignedRolePerDepartment[] = [];
        org.departments.map((dept: Department) => {
          dept.roles.forEach((deptRole: DepartmentRole) => {
            if (assignableRoles && assignableRoles.includes(deptRole.friendlyId)) {
              const model: AssignedRolePerDepartment = {
                departmentId: dept.departmentId,
                roleId: deptRole.roleId
              };
              models.push(model);
            }
          });
        });
        return models;
      })
    );
  }
}

export const mapToVm = (userRoles: UserRole[]) => {
  if (!userRoles || userRoles.length === 0) {
    return [];
  }
  return userRoles.reduce((a: AssignedRolePerDepartment[], role: UserRole) => {
    const w = role.departments.map(d => {
      return {
        departmentId: d.departmentId,
        roleId: role.roleId
      } as AssignedRolePerDepartment;
    });
    a = a.concat(w);
    return a;
  }, []);
};
