import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { AngularEditorComponent, AngularEditorConfig } from "@kolkov/angular-editor";
import { Select, Store } from "@ngxs/store";
import * as ContentFilterStateActions from "@vp/data-access/content-management";
import {
  ContentDataFilter,
  ContentDetails,
  ContentFilterState,
  ContentManagementService
} from "@vp/data-access/content-management";
import { ContentData, User } from "@vp/models";
import { NotificationService } from "@vp/shared/notification-service";
import { filterNullMap } from "@vp/shared/operators";
import { ANGULAR_EDITOR_CONFIG } from "@vp/shared/tokens";
import { mergeDeep } from "@vp/shared/utilities";
import { UserAdministrationState } from "@vp/user-administration/data-access/user-administration-state";
import { BehaviorSubject, Observable, Subject, combineLatest } from "rxjs";
import { map, switchMap, take, takeUntil, tap } from "rxjs/operators";
export type PageMode = "desktop" | "mobile";

@Component({
  selector: "vp-user-bio-dialog",
  templateUrl: "./user-bio-dialog.component.html",
  styleUrls: ["./user-bio-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: Window, useValue: window }]
})
export class UserBioDialogComponent implements OnDestroy, OnInit, AfterViewInit {
  @Select(ContentFilterState.workingCopy) workingCopy$!: Observable<ContentData | null>;
  @Select(ContentFilterState.currentFilter) currentFilter$!: Observable<Partial<ContentDataFilter>>;
  @Select(UserAdministrationState.user) user$!: Observable<User | null>;

  config: AngularEditorConfig;
  form!: UntypedFormGroup;
  errors$!: Observable<string[]>;
  showErrors$!: Observable<boolean>;
  defaultContent!: ContentData;

  private readonly _destroyed$ = new Subject();
  private readonly _errors$ = new BehaviorSubject<string[]>([]);
  private readonly _cursorPosition$ = new BehaviorSubject<Range | null | undefined>(null);

  @ViewChild(AngularEditorComponent)
  public angularEditor!: AngularEditorComponent;

  constructor(
    @Inject(ANGULAR_EDITOR_CONFIG) public angularEditorConfig: AngularEditorConfig,
    private readonly formBuilder: UntypedFormBuilder,
    public contentManagementService: ContentManagementService,
    private readonly notificationService: NotificationService,
    private dialogRef: MatDialogRef<UserBioDialogComponent>,
    private readonly store: Store,
    private window: Window
  ) {
    this.config = {
      ...angularEditorConfig,
      placeholder: "Enter text here...",
      minHeight: "40em",
      rawPaste: false,
      sanitize: false,
      toolbarHiddenButtons: [
        ["fontName"],
        [
          "insertImage",
          "insertVideo",
          "link",
          "unlink",
          "customClasses",
          "backgroundColor",
          "insertHorizontalRule"
        ]
      ]
    };

    this.errors$ = this._errors$.asObservable();
    this.showErrors$ = this._errors$.pipe(map(e => e.length > 0));
  }

  contentDetails$ = this.user$.pipe(
    takeUntil(this._destroyed$),
    filterNullMap(),
    map((user: User) => {
      const currentFilter = this.store.selectSnapshot(ContentFilterState.currentFilter);
      const contentDetails = {
        contentId: `userBio-${user.userId}`,
        contentTypeId: "userBio"
      } as ContentDetails;
      this.store.dispatch(
        new ContentFilterStateActions.SetFilter({
          ...currentFilter,
          contentTypeId: contentDetails.contentTypeId,
          contentId: contentDetails.contentId
        })
      );
      return contentDetails;
    })
  );

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit(): void {
    this.buildForm();
    this.setState();

    this.form?.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        switchMap((formData: Record<string, unknown>) => {
          return this.getUpdatedWorkingCopy$(formData);
        }),
        switchMap((merged: ContentData) => {
          this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(merged));
          return this.contentManagementService.updateWorkingCopy();
        })
      )
      .subscribe();
  }

  onPaste(event: any): void {
    event.preventDefault();

    const clipboardData = event.clipboardData || event.originalEvent.clipboardData;
    let pastedHtml = clipboardData.getData("text/html");

    if (pastedHtml === "") {
      pastedHtml = clipboardData.getData("text/plain");
    }

    const extractedContent = this.extractBodyContent(pastedHtml);
    const cleanedHtml = extractedContent
      .replace(/<!--StartFragment-->/gi, "")
      .replace(/<!--EndFragment-->/gi, "")
      .replace(/}<\/span>}/g, "}}</span>");

    if (this._cursorPosition$) {
      const node = document.createRange().createContextualFragment(cleanedHtml);
      this._cursorPosition$.getValue()?.insertNode(node as Node);
      this.angularEditor.onContentChange(this.angularEditor.textArea.nativeElement);
    }
  }

  extractBodyContent(html: string): string {
    // Use a regular expression to extract content between <body> tags
    const bodyRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
    const match = bodyRegex.exec(html);

    if (match && match.length >= 2) {
      return match[1]; // Extracted content between <body> tags
    } else {
      // If no <body> tags are found, return the original HTML
      return html;
    }
  }

  ngAfterViewInit() {
    // Wait for the view to be initialized, then you can access the editor's text area
    const editorTextArea: HTMLElement = this.angularEditor.textArea.nativeElement;

    // Add an event listener for the input event on the text area
    editorTextArea.addEventListener("input", this.onEditorInput.bind(this));
  }

  onEditorInput() {
    // Get the selection object
    const selection = window.getSelection();

    // Check if there is a selection
    if (selection) {
      // Get the range of the current selection
      const range = selection.getRangeAt(0);

      // Log the start offset of the range as the cursor position
      this._cursorPosition$.next(range);
    }
  }

  getUpdatedWorkingCopy$(formData: Record<string, unknown>) {
    return combineLatest([
      this.user$.pipe(filterNullMap()),
      this.workingCopy$.pipe(filterNullMap()),
      this.contentDetails$
    ]).pipe(
      take(1),
      map(([user, workingCopy, contentDetails]: [User, ContentData, ContentDetails]) => {
        const updated = {
          ...workingCopy,
          createdBy: workingCopy.createdBy ?? user.userId,
          lastUpdatedBy: user.userId,
          contentId:
            workingCopy.contentId == null
              ? `${contentDetails.contentTypeId}-${user.userId}`
              : workingCopy.contentId,
          contentTypeId:
            workingCopy.contentTypeId == null
              ? contentDetails.contentTypeId
              : workingCopy.contentTypeId,
          displayName:
            workingCopy.displayName == null
              ? `${user.profile.lastName},${user.profile.firstName}'s Bio.`
              : workingCopy.displayName,
          friendlyId:
            workingCopy.friendlyId == null
              ? `${contentDetails.contentTypeId}-${user.email}`
              : workingCopy.friendlyId,
          description:
            workingCopy.description == null
              ? `${user.profile.lastName},${user.profile.firstName}'s Bio.`
              : workingCopy.description,
          active: true,
          tags: workingCopy.tags
        };
        const merged = mergeDeep({ ...updated }, formData, "replace", true) as ContentData;
        if (workingCopy.contentTypeId != merged.contentTypeId) {
          merged.tags = [];
        }
        return merged;
      })
    );
  }

  setState() {
    this.contentDetails$
      .pipe(
        takeUntil(this._destroyed$),
        switchMap((contentDetails: ContentDetails) => {
          this.defaultContent = this.contentManagementService.getDefaultContent(
            contentDetails.contentId,
            contentDetails.contentTypeId
          );
          return this.contentManagementService.loadExistingContent(this.defaultContent);
        }),
        take(1),
        tap((contentData: ContentData) => {
          this.form.patchValue({
            content: contentData.content
          });
        })
      )
      .subscribe();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      content: [""],
      file: this.formBuilder.group({
        fileName: [{ value: "", disabled: true }, Validators.required],
        fileTextBase64: [""]
      })
    });
  }

  saveSelection() {
    const sel = this.window.getSelection();
    if (sel && sel.getRangeAt && sel.rangeCount) {
      this._cursorPosition$.next(sel.getRangeAt(0));
    }
  }

  fileSelectedHandler(
    editor: AngularEditorComponent,
    event: Event | File,
    imageInput: HTMLInputElement
  ): void {
    let file: File | null | undefined;
    if (event instanceof Event) {
      const target = event.target as HTMLInputElement;
      const fileList = target.files as FileList;
      file = fileList[0];
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = event => {
        this.setImage(event.target?.result as string, editor);
        const result = event.target?.result as string;

        const prefixLength = result.indexOf(",") + 1;
        const encodedString = result.substring(prefixLength);

        if (!encodedString) {
          this.notificationService.warningMessage("Unable to upload empty files", "Warning");
          return;
        }
        imageInput.value = "";
      };
      reader.readAsDataURL(file);
    }
  }

  private readonly setImage = (url: string, editor: AngularEditorComponent): void => {
    const image = new Image();
    image.src = url as string;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    image.onload = () => {
      const factor = 250 / image.width;
      canvas.width = image.width * factor;
      canvas.height = image.height * factor;

      ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);

      const result = canvas.toDataURL();

      const imageElement = `<img src="${result}" alt="Image" > `;
      if (!this._cursorPosition$.getValue()) {
        this.notificationService.warningMessage("No cursor position selected");
      } else if (this._cursorPosition$?.getValue() && imageElement) {
        const node = document.createRange().createContextualFragment(imageElement);
        this._cursorPosition$.getValue()?.insertNode(node as Node);
        editor.onContentChange(editor.textArea.nativeElement);
      } else {
        this.form.get("content")?.patchValue(`<img src="${result}" alt="Image" > `);
      }
    };
  };

  //this prevents extra html tags from being added to the pasted text inside link handlebarhelper.

  cleanHandlebarContent(input: string): string {
    // Match either single or double quotes, then use a backreference to match the corresponding closing quote
    const handlebarRegex = /{{link\s+(['"])([^'"]+)\1\s+(['"])([^'"]+)\3}}/g;

    return input.replace(handlebarRegex, (_, q1, displayText, q2, content) => {
      // Remove HTML tags from content
      const cleanedContent = content.replace(/<\/?[^>]+(>|$)/g, "");
      return `{{link ${q1}${displayText}${q1} ${q2}${cleanedContent}${q2}}}`;
    });
  }

  saveChanges() {
    const workingCopy = this.store.selectSnapshot(ContentFilterState.workingCopy);
    if (workingCopy) {
      this.contentManagementService.createOrEditContent(workingCopy).subscribe({
        next: () => {
          this.notificationService.successMessage("User's Bio modified successfully");
          this.dialogRef.close();
        },
        error: error => {
          this._errors$.next([error]);
        }
      });
    }
  }
}
