<mat-form-field>
  <mat-label>{{ props.label }}</mat-label>
  <input
    [formControl]="formControl"
    matInput
    [required]="props.required ? true : false"
    [max]="maxDate"
    [matDatepicker]="picker"
    (dateChange)="formatDate($event)"
    [errorStateMatcher]="dobErrorStateMatcher"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error>This field is required</mat-error>
</mat-form-field>
