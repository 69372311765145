import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthenticationGuard, RedirectGuard } from "@vp/shared/guards";
import { UserProfileGuard } from "@vp/user-profile/feature";
import { environment } from "../environments/environment";
import { ErrorComponent } from "./pages/error/error.component";
import { UninvitedComponent } from "./pages/uninvited/uninvited.component";

const routes: Routes = [
  {
    path: "login",
    data: {
      breadcrumb: "homeNav"
    },
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginModule)
  },
  { path: "error", component: ErrorComponent },
  { path: "uninvited", component: UninvitedComponent },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "loggedout", redirectTo: "/login", pathMatch: "full" },
  {
    path: "forbidden",
    loadChildren: () => import("./pages/forbidden/forbidden.module").then(m => m.ForbiddenModule)
  },
  {
    path: "unauthorized",
    loadChildren: () =>
      import("./pages/unauthorized/unauthorized.module").then(m => m.UnauthorizedModule)
  },
  {
    path: "wizard",
    data: {
      breadcrumb: "caseNav"
    },
    loadChildren: () => import("@vp/case-create/feature").then(m => m.CaseCreateFeatureModule)
  },

  // BEGIN authenticated only routes
  {
    path: "login/callback",
    pathMatch: "full",
    canActivate: [AuthenticationGuard, RedirectGuard],
    children: []
  },
  {
    path: "loggedin",
    pathMatch: "full",
    canActivate: [AuthenticationGuard, RedirectGuard],
    children: []
  },
  {
    path: "default",
    pathMatch: "full",
    canActivate: [AuthenticationGuard, RedirectGuard],
    children: []
  },
  {
    path: "home",
    canLoad: [AuthenticationGuard],
    loadChildren: () => import("../app/pages/home/home.module").then(m => m.HomeModule)
  },
  {
    path: "profile",
    canLoad: [AuthenticationGuard],
    data: {
      breadcrumb: "profile"
    },
    loadChildren: () => import("@vp/user-profile/feature").then(m => m.UserProfileFeatureModule)
  },
  {
    path: "case",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    loadChildren: () => import("@vp/case-dashboard/feature").then(m => m.CaseDashboardFeatureModule)
  },
  {
    path: "cases",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("@vp/case-request/feature").then(m => m.CaseRequestFeatureModule)
  },
  {
    path: "devices",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("@vp/direct-connect/feature").then(m => m.DirectConnectFeatureModule)
  },
  {
    path: "patients",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () =>
      import("@vp/patient-manager/feature").then(m => m.PatientManagerFeatureModule)
  },
  {
    path: "queue",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("@vp/case-queue/feature").then(m => m.CaseQueueFeatureModule)
  },
  {
    path: "administration",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("@vp/administration/feature").then(m => m.AdministrationModule)
  },
  {
    path: "tagmanager",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("@vp/tag-manager/feature").then(m => m.TagManagerFeatureModule)
  },
  {
    path: "about",
    outlet: "sidebar",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("./pages/about/about.module").then(m => m.AboutModule)
  },
  {
    path: "content",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () =>
      import("@vp/content-management/feature").then(m => m.ContentManagementFeatureModule)
  },
  {
    path: "system-information",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () =>
      import("@vp/system-information/feature").then(m => m.SystemInformationFeatureModule)
  },
  {
    path: "vcompanion",
    canLoad: [AuthenticationGuard],
    canActivate: [UserProfileGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => import("@vp/v-companion/feature").then(m => m.VCompanionFeatureModule)
  },
  { path: "**", redirectTo: "/default", pathMatch: "full" }
];

export const enableTracing = false; // <-- Set to true to debug router in dev mode

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: environment.production ? false : enableTracing,
      useHash: false,
      onSameUrlNavigation: "reload",
      initialNavigation: "disabled",
      relativeLinkResolution: "legacy"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export function isInIframe() {
  return window !== window.parent && !window.opener;
}
