import { Inject, Injectable } from "@angular/core";
import { FormlyFormOptions } from "@ngx-formly/core";
import {
  FORM_STATE_PROVIDERS,
  FormStateProvider,
  UiSchema,
  UiSchemaConfigService
} from "@vp/formly/ui-schema-config";
import { deeperCopy, mergeDeep } from "@vp/shared/utilities";
import { Observable, from, of } from "rxjs";
import { concatMap, map, mergeMap, toArray } from "rxjs/operators";

/*
  This entire class is temporary this functionality will be moved into a provider to live close to the app possibly
  with the logic provided as a factory or class provider.
*/
@Injectable({
  providedIn: "root"
})
export class UiSchemaStateProvider {
  constructor(
    @Inject(FORM_STATE_PROVIDERS) private formStateProviders: FormStateProvider[],
    private configService: UiSchemaConfigService
  ) {}

  applyScopes(
    type: string,
    formlyFormOptions: FormlyFormOptions = {},
    scope: string | null = null,
    context?: Record<string, unknown>
  ): Observable<FormlyFormOptions> {
    let clone = {};
    if (Object.keys(formlyFormOptions).length === 0) {
      clone = {
        formState: {}
      };
    } else {
      clone = deeperCopy(formlyFormOptions);
    }

    const elements = this.configService
      .getLayoutElements(type, scope)
      .filter(e => e.formlyFormOptions);

    if (elements.length > 0) {
      return from(elements).pipe(
        concatMap((element: UiSchema) => this.applyFormState(clone, element, context))
      );
    }
    return of(clone);
  }

  private applyFormState(
    formlyFormOptions: FormlyFormOptions,
    element: UiSchema,
    context?: Record<string, unknown>
  ): Observable<FormlyFormOptions> {
    return from(this.formStateProviders).pipe(
      mergeMap(provider => {
        return provider.provide(element.formlyFormOptions?.formState, context).pipe(
          map(formState => {
            const merged = mergeDeep(formlyFormOptions, {
              formState: formState
            });
            return merged;
          })
        );
      }),
      toArray(),
      map(arrayOfFormlyFormOptions => {
        return arrayOfFormlyFormOptions.reduce((acc, curr) => {
          return mergeDeep(acc, curr);
        }, {} as FormlyFormOptions);
      })
    );
  }
}
