import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, QueryParamsHandling, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { filterNullMap } from "@vp/shared/operators";
import { objectsEqual } from "@vp/shared/utilities";
import { EMPTY, Observable, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  map,
  takeUntil,
  tap
} from "rxjs/operators";
import { ContentDataFilter } from "../models/content-data-filter";
import * as ContentFilterStateActions from "../state+/content-filter-state.actions";
import * as ContentFilterState from "../state+/content-filter.state";

@Injectable()
export class ContentFilterStateActionsService implements OnDestroy {
  @Select(ContentFilterState.ContentFilterState.currentFilter)
  currentFilter$!: Observable<ContentDataFilter>;

  private _destroy$ = new Subject();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  listen() {
    this.activatedRoute.queryParamMap
      .pipe(
        takeUntil(this._destroy$),
        map((paramMap: ParamMap) => paramMap.get("search")),
        filterNullMap(),
        exhaustMap((search: string) => {
          const currentFilter = this.store.selectSnapshot(
            ContentFilterState.ContentFilterState.currentFilter
          );
          if (currentFilter.search !== search) {
            return this.store.dispatch(
              new ContentFilterStateActions.SetFilter({
                ...currentFilter,
                search: search
              })
            );
          }
          return EMPTY;
        })
      )
      .subscribe();

    this.currentFilter$
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(300),
        distinctUntilChanged((prev, curr) => objectsEqual(prev, curr)),
        tap(filter => {
          this.navigate(filter);
          this.store.dispatch(new ContentFilterStateActions.GetFiltered());
        })
      )
      .subscribe();
  }

  navigate(
    searchParams: Partial<ContentDataFilter>,
    queryParamsHandling: QueryParamsHandling | null = "",
    skipLocationChange = false,
    replaceUrl = true
  ): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: searchParams,
      queryParamsHandling,
      skipLocationChange,
      replaceUrl
    });
  }
}
