import { formatDate } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import { DateAdapter, ErrorStateMatcher, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import moment from "moment";

export enum DateDirection {
  Past = "past",
  Future = "future",
  Both = "both"
}

@Component({
  selector: "lib-formly-dob-picker",
  templateUrl: "./formly-dob-picker.component.html",
  styleUrls: ["./formly-dob-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: FormlyDobPickerComponent },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ]
})
export class FormlyDobPickerComponent extends FieldType<FieldTypeConfig> implements OnInit {
  maxDate!: Date | null;
  minDate!: Date | null;
  dobErrorStateMatcher = new DobErrorStateMatcher();

  @ViewChild("picker") picker: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.props.dateDirection === DateDirection.Past) {
      this.maxDate = new Date();
      this.minDate = null;
    }
  }

  formatDate(event: any): void {
    if (event.value) {
      const d = new Date(event.value);
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      const formattedDate = formatDate(d, "YYYY-MM-dd", "en-US");
      this.model[this.key as string] = formattedDate;
      this.formControl.setValue(formattedDate, { emitEvent: false });
    }
  }
}

export class DobErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    if (control?.invalid && control?.value && control?.value instanceof moment) {
      control.setErrors(null);
    }
    return !!(control?.invalid && (control?.dirty || control?.touched));
  }
}
