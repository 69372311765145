import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { FieldTypeConfig } from "@ngx-formly/core";
import { FieldType } from "@ngx-formly/material/form-field";
import { objectHasProperty } from "@vp/shared/utilities";
import { NgxLinkifyjsService } from "ngx-linkifyjs";

@Component({
  selector: "lib-formly-json-schema-form-input",
  templateUrl: "./form-input.component.html",
  styleUrls: ["./form-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormInputComponent extends FieldType<FieldTypeConfig> implements OnInit {
  inputErrorMatcher = new InputErrorMatcher();
  url: string | undefined;
  constructor(public linkifyService: NgxLinkifyjsService) {
    super();
  }

  ngOnInit(): void {
    this.extractURL();
  }

  extractURL() {
    if (this.field.model && this.field.key && objectHasProperty(this.field.model, this.field.key)) {
      const theValue = this.field.model[this.field.key as string];
      if (typeof theValue === "string") {
        const isUrl = this.linkifyService.find(theValue);
        if (isUrl && isUrl.length > 0 && isUrl[0].type === "url") {
          this.url = isUrl[0].href;
        }
      }
    }
  }
}

export class InputErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    return !!(control?.invalid && (control?.dirty || control?.touched));
  }
}
