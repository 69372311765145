import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { Store } from "@ngxs/store";
import { TagsState } from "@vp/data-access/tags";
import { Tag } from "@vp/models";
import { TagGroup, getTagDifferences } from "@vp/shared/utilities";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TagChangedEvent } from "./multi-tag-selector.component";

@Component({
  selector: "vp-formly-multi-tag-selector",
  templateUrl: "./formly-multi-tag-selector.component.html",
  styleUrls: ["./formly-multi-tag-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMultiTagSelectorComponent
  extends FieldType
  implements OnInit, AfterViewInit, OnDestroy
{
  private selectedTags$$ = new BehaviorSubject<Tag[]>([]);
  private tagTypes$$ = new BehaviorSubject<string[]>([]);
  private tagGroups$$ = new BehaviorSubject<TagGroup[]>([]);
  private destroyed$ = new Subject();

  constructor(private readonly store: Store) {
    super();
  }

  selectedTags$ = this.selectedTags$$.asObservable();
  tagTypes$ = this.tagTypes$$.asObservable();
  tagGroups$ = this.tagGroups$$.asObservable();

  ngOnInit(): void {
    if (Array.isArray(this.props.tagTypes) && this.props.tagTypes?.length > 0) {
      this.tagTypes$$.next([...this.props.tagTypes]);
    }

    if (Array.isArray(this.formControl.value) && this.formControl.value.length > 0) {
      const _allTags = this.store.selectSnapshot(TagsState.filtered);
      const selectedTagIds = this.formControl.value.map((x: any) => x.tagId);
      const selected = _allTags.filter(x => selectedTagIds.includes(x.tagId)) || ([] as Tag[]);
      this.selectedTags$$.next([...selected]);
    }

    if (Array.isArray(this.formState.tagGroups) && this.formState?.tagGroups.length > 0) {
      this.tagGroups$$.next([...this.formState.tagGroups]);
    }
  }

  ngAfterViewInit(): void {
    this.selectedTags$$.pipe(takeUntil(this.destroyed$)).subscribe((selectedTags: Tag[]) => {
      if (selectedTags.length === 0) return;
      const tagFtilers = selectedTags.map(t => {
        return {
          tagId: t.tagId,
          tagFriendlyId: t.tagTypeFriendlyId
        };
      });
      this.formControl.setValue(tagFtilers);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onTagChanged(tagChangeEvent: TagChangedEvent) {
    const _selectedTags = this.selectedTags$$.getValue() ?? [];
    const _currentFilterTags = _selectedTags.map(t => t.tagId);
    const _allTags = this.store.selectSnapshot(TagsState.filtered);
    const _tagGroups = this.tagGroups$$.getValue().filter(tagGroup => {
      return this.props.tagTypes.includes(tagGroup.tagTypeFriendlyId);
    });

    const differences = getTagDifferences(
      _allTags.filter(t => _currentFilterTags.includes(t.tagId)),
      _allTags.filter(t => tagChangeEvent.tags.includes(t.tagId)),
      tagChangeEvent.tagTypeFriendlyId
    );

    if (differences.added.length > 0) {
      const addedChildTags = _tagGroups.reduce((acc: string[], tagGroup: TagGroup) => {
        if (differences.added.includes(tagGroup.key)) {
          acc.push(
            ...tagGroup.children
              .filter(t => this.props.tagTypes.includes(t.tag.tagTypeFriendlyId))
              .map(t => t.tag.tagId)
          );
        }
        return acc;
      }, []);

      const _selectedTagIds = [
        ...new Set([..._currentFilterTags, ...differences.added, ...addedChildTags])
      ];
      const _tags = _allTags.filter(t => _selectedTagIds.includes(t.tagId));
      this.selectedTags$$.next(_tags);
    }

    if (differences.removed.length > 0) {
      const removedChildtags: string[] = _tagGroups
        .filter(tg => differences.removed.includes(tg.key))
        .map(tg => tg.children.map(t => t.tag.tagId))
        .reduce((acc, val) => [...acc, ...val], []);
      const _selectedTagIds = [
        ..._currentFilterTags.filter(
          t => !differences.removed.includes(t) && !removedChildtags.includes(t)
        )
      ];
      const _tags = _allTags.filter(t => _selectedTagIds.includes(t.tagId));
      this.selectedTags$$.next(_tags);
    }
  }
}
