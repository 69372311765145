import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  CasePaymentRefund,
  PaymentPayload,
  PaymentRequestResponse,
  PaymentTransactionResult
} from "@vp/models";
import { API_BASE_URL } from "@vp/shared/tokens";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CybersourceService {
  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, private http: HttpClient) {}

  getTransientToken() {
    const apiURL = `${this.apiBaseUrl}/cybersource/tokenKey`;
    return this.http.get(apiURL);
  }

  submitPayment(caseId: string, paymentPayload: PaymentPayload) {
    const apiURL = `${this.apiBaseUrl}/cybersource/${caseId}/payment`;
    return this.http
      .post(apiURL, paymentPayload)
      .pipe(map((response: any) => this.mapTransactionResult(response)));
  }

  refundPayment(paymentRefund: CasePaymentRefund, transactionId: string) {
    const apiURL = `${this.apiBaseUrl}/cybersource/payment/refund?transactionId=${transactionId}`;
    return this.http.post(apiURL, paymentRefund);
  }

  private mapTransactionResult(response: PaymentRequestResponse) {
    return {
      status: response.paymentMade ? "success" : "failed",
      message: response.message,
      record: {
        transactionId: response.transactionId
      }
    } as PaymentTransactionResult;
  }
}
