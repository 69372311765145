import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Select } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { Snippet, User, UserData } from "@vp/models";
import { NotificationService } from "@vp/shared/notification-service";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { AddOrEditSnippetDialogComponent } from "@vp/user-snippets/ui/add-or-edit-snippet-dialog";
import { MAX_SNIPPETS } from "@vp/user-snippets/ui/user-snippets";
import { EMPTY, Observable, of, zip } from "rxjs";
import { concatMap, map, take, withLatestFrom } from "rxjs/operators";

@Component({
  selector: "vp-insert-snippet",
  templateUrl: "./insert-snippet.component.html",
  styleUrls: ["./insert-snippet.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsertSnippetComponent {
  @Select(ApplicationState.loggedInUser) loggedInUser$!: Observable<User | null>;

  @Output() insertSnippetEvent = new EventEmitter<string>();

  selectedSnippet: Snippet | undefined = undefined;

  snippets$ = this.loggedInUser$.pipe(
    filterNullMap(),
    map(user => (user.userData?.snippets as Snippet[]) || [])
  );

  disableAddSnippet$ = this.snippets$.pipe(map(snippets => snippets.length >= MAX_SNIPPETS));

  constructor(
    private readonly dialog: MatDialog,
    private appStore: AppStoreService,
    private notificationService: NotificationService
  ) {}

  addNewSnippet() {
    this.snippets$
      .pipe(
        take(1),
        concatMap(snippets => {
          if (snippets.length >= MAX_SNIPPETS) {
            this.notificationService.warningMessage(
              `Unable to add snippet, ${MAX_SNIPPETS} maximum snippets reached`
            );
            return EMPTY;
          }
          const dialogRef = this.dialog.open(AddOrEditSnippetDialogComponent, {
            width: "500px",
            data: { action: "Add" }
          });

          return zip(dialogRef.afterClosed(), of(snippets));
        }),
        withLatestFrom(this.loggedInUser$.pipe(filterNullMap())),
        concatMap(([[dialogResult, snippets], user]) => {
          if (dialogResult) {
            if (!user.userData) {
              user.userData = {} as UserData;
            }
            return this.appStore.patchUser(
              {
                ...user,
                userData: {
                  ...user.userData,
                  snippets: [...snippets].concat(dialogResult)
                }
              },
              "user_addNewSnippet"
            );
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.notificationService.successMessage("Snippet Added");
      });
  }

  insertSnippet() {
    if (this.selectedSnippet?.text) {
      this.insertSnippetEvent.emit(this.selectedSnippet?.text);
    }
  }
}
