import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Select } from "@ngxs/store";
import { TagsState } from "@vp/data-access/tags";
import { SchemaFieldType } from "@vp/formly/json-schema";
import { Tag } from "@vp/models";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { CaseTypeSelectorService } from "libs/shared/components/case-type-selector/src/lib/case-type-selector.service";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
  selector: "vp-formly-condition-type-selector",
  templateUrl: "./formly-condition-type-selector.component.html",
  styleUrls: ["./formly-condition-type-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CaseTypeSelectorService]
})
export class FormlyConditionTypeSelectorComponent extends SchemaFieldType implements OnDestroy {
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  selectedTag: Tag | null = null;
  private destroyed$ = new Subject();

  constructor(
    public uiDisplayTagService: UiDisplayTagService,
    private readonly caseTypeSelectorService: CaseTypeSelectorService
  ) {
    super();
  }

  conditionTypeTags$ = this.tags$.pipe(
    takeUntil(this.destroyed$),
    map(tags => tags.filter(tag => tag.tagTypeFriendlyId === this.props.tagTypeFriendlyId))
  );

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  conditionTypeSelected(tag: Tag) {
    this.selectedTag = tag;
    this.formControl.setValue(tag.tagId);
    const casetypeIds = tag.tagData?.allowedCaseTypes as string[];
    this.caseTypeSelectorService.setSelected(casetypeIds[0]);
  }
}
