<mat-toolbar class="menu site-header" flexLayout="row" flexLayoutAlign="space-between stretch">
  <div flex flexLayout="row" flexLayoutAlign="start stretch">
    <vp-top-nav-logo
      class="logo"
      flexLayout="row"
      flexLayoutAlign="start center"
      flexShow.lt-md="false"
    ></vp-top-nav-logo>
    <vp-top-nav-list
      flexLayout="row"
      flexLayoutAlign="start stretch"
      [menuOption]="false"
      [menuItems]="(menuItems$ | async) ?? []"
    ></vp-top-nav-list>
  </div>

  <div flexLayout="row" flexLayoutAlign="end center" flexLayoutGap="1rem">
    <vp-top-nav-search class="search"></vp-top-nav-search>
    <vp-top-nav-user></vp-top-nav-user>
    <button
      *ngIf="showExtendedMenu | async"
      mat-icon-button
      type="button"
      color="primary"
      [matMenuTriggerFor]="dropMenu"
    >
      <mat-icon inline="true">menu</mat-icon>
    </button>
    <mat-menu #dropMenu="matMenu">
      <!-- <vp-top-nav-search [flexShow]="false"></vp-top-nav-search> -->
      <button
        *ngFor="let item of (menuItems$ | async) ?? []"
        mat-menu-item
        type="button"
        routerLinkActive="active"
        [routerLink]="item.routerLink"
      >
        {{ item?.displayName }}
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<div *ngIf="isLoading$ | async" class="center">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
